import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Chip, Theme } from '@mui/material';
import useProductCount from '@app/web/src/hooks/utils/useProductCount';
import { Icon } from '@front/ui';
import { SharedUserContext } from '@lib/web/contexts';
import { MenuComps } from '@lib/web/ui';

import getMarketplacePageConfig from '../../../pageConfig/marketplace';

const getCountNumber = (number?: number) => {
  if (!number) return '0';
  if (number > 99) return '99+';
  return number;
};

const styles = {
  count: {
    height: '22px',
    ml: 1,
    color: 'text.primary',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    '& span': {
      px: 1,
    },
  },
};

export default function MarketplaceMenu() {
  const { t } = useTranslation();
  const { data: productCount } = useProductCount();
  const { sharePath } = useContext(SharedUserContext);
  const pageConfig = getMarketplacePageConfig(t);

  return (
    <MenuComps title={t('marketplace.title', 'Avatar Marketplace')}>
      <MenuComps.Section>
        <MenuComps.Button
          icon={<Icon name={pageConfig.available.icon} />}
          href={`${sharePath}/marketplace/available`}
        >
          {pageConfig.available.title}
          <Chip
            label={getCountNumber(productCount.available)}
            size="small"
            sx={styles.count}
          />
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.unavailable.icon} />}
          href={`${sharePath}/marketplace/unavailable`}
        >
          {pageConfig.unavailable.title}
          <Chip
            label={getCountNumber(productCount.unavailable)}
            size="small"
            sx={styles.count}
          />
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.comingSoon.icon} />}
          href={`${sharePath}/marketplace/coming-soon`}
        >
          {pageConfig.comingSoon.title}
          <Chip
            label={getCountNumber(productCount.comingSoon)}
            size="small"
            sx={styles.count}
          />
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.myFavorite.icon} />}
          href={`${sharePath}/marketplace/my-favorite`}
        >
          {pageConfig.myFavorite.title}
          <Chip
            label={getCountNumber(productCount.favorite)}
            size="small"
            sx={styles.count}
          />
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.myAvatars.icon} />}
          href={`${sharePath}/marketplace/my-avatars`}
        >
          {pageConfig.myAvatars.title}
          <Chip
            label={getCountNumber(productCount.myAvatars)}
            size="small"
            sx={styles.count}
          />
        </MenuComps.Button>
      </MenuComps.Section>
    </MenuComps>
  );
}

import { IaAction } from '@lib/ia/src/core/types';

export const getClickAction = (
  item: GetNotificationRes
): (IaAction & { metadata?: Record<string, string> }) | undefined => {
  switch (item.tag) {
    case 'challenge.good.result.member.club':
    case 'challenge.almost.end.scheduler.challenger.club':
    case 'challenge.ending.scheduler.challenger.club':
    case 'challenge.ending.scheduler.winner.club':
    case 'challenge.acceptance.addressee.addressee.club': {
      const body = item.data.payload.body;
      let quizShortId;
      if ('quizShortId' in body) {
        quizShortId = body.quizShortId;
      }
      return {
        type: 'event',
        value: 'goToPlaylistDetails',
        metadata: {
          quizId: body.quizId,
          quizShortId: quizShortId || '',
          clubSlug: body.clubSlug,
        },
      };
    }
    case 'email.verification.success.global': {
      return {
        type: 'event',
        value: 'goToDiscoverClubs',
      };
    }
  }
};

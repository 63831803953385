import { ChallengeCategoryStatus, useChallengeAll } from '@lib/web/apis';
import { useIaClub } from '@lib/web/hooks';

export default function useChallengeCount(clubSlug: string) {
  const { sectionId } = useIaClub(clubSlug);

  const {
    data: invitedCount,
    mutate: invitedMutate,
    isLoading: isInvitedLoading,
  } = useChallengeAll(sectionId, ChallengeCategoryStatus.Invited, undefined, 1);
  const {
    data: ongoingCount,
    mutate: ongoingMutate,
    isLoading: isOngoingLoading,
  } = useChallengeAll(sectionId, ChallengeCategoryStatus.Ongoing, undefined, 1);
  const {
    data: expiredCount,
    mutate: expiredMutate,
    isLoading: isExpiredLoading,
  } = useChallengeAll(sectionId, ChallengeCategoryStatus.Expired, undefined, 1);
  const {
    data: openChallengeCount,
    mutate: openChallengeMutate,
    isLoading: isOpenChallengeLoading,
  } = useChallengeAll(
    sectionId,
    ChallengeCategoryStatus.OpenChallenge,
    undefined,
    1
  );

  const mutate = () => {
    invitedMutate();
    ongoingMutate();
    expiredMutate();
    openChallengeMutate();
  };

  return {
    mutate,
    data: {
      invited: invitedCount?.data.totalCount,
      ongoing: ongoingCount?.data.totalCount,
      expired: expiredCount?.data.totalCount,
      openChallenges: openChallengeCount?.data.totalCount,
    },
    isLoading:
      isInvitedLoading ||
      isOngoingLoading ||
      isExpiredLoading ||
      isOpenChallengeLoading,
  };
}

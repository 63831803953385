import { Box, ButtonBase, ButtonBaseProps, Typography } from '@mui/material';
import useMenuClub from '@app/web/src/hooks/utils/useMenuClub';
import { SquareAvatarProgress } from '@front/ui';
import { ClubJoinedStatus } from '@lib/web/apis';
import { getClubIconPath } from '@lib/web/utils';

import { JoinButton } from './JoinButton';
import { styles } from './styles';
import useSwitchClub from './useSwitchClub';

export default function ClubButton({
  club,
  onJoined,
  onHover,
  sx,
  disableSwitchClubAfterJoined,
}: {
  club:
    | IaClubGroupClubProp
    | GetIaClubCardCenterDefaultViewRes
    | GetIaSimilarClubCardCenterDefaultViewRes;
  sx?: ButtonBaseProps['sx'];
  disableSwitchClubAfterJoined?: boolean;
  onJoined?: () => void;
  onHover?: () => void;
}) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const { club: menuClub } = useMenuClub();
  const switchClub = useSwitchClub();
  const handleJoinClub = () => {
    onJoined?.();
    if (!disableSwitchClubAfterJoined) switchClub(club.clubSlug, true);
  };
  const isJoined = club.joinStatus === ClubJoinedStatus.Joined;
  const handleSwitchClub = () => {
    onJoined?.();

    switchClub(club.clubSlug, isJoined);
  };
  return (
    <Box sx={styles.buttonWrap}>
      <ButtonBase
        sx={[styles.button, ...sxProps]}
        onClick={handleSwitchClub}
        onMouseEnter={onHover}
        component="div"
      >
        <Box sx={styles.buttonAvatarText}>
          <SquareAvatarProgress
            src={club.clubLogo || getClubIconPath(club.clubName)}
            size={36}
            ratio={menuClub?.clubSlug === club.clubSlug ? 1 : 0}
          >
            {club.clubName}
          </SquareAvatarProgress>

          <Typography fontWeight="bold">{club.clubName}</Typography>
        </Box>
        {!isJoined && (
          <Box sx={styles.buttonActions}>
            <JoinButton
              slug={club.clubSlug}
              name={club.clubName}
              onJoined={handleJoinClub}
              onAccepted={handleJoinClub}
            />
          </Box>
        )}
      </ButtonBase>
    </Box>
  );
}

import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { updateViewedSlug } from '@app/web/src/actions/layoutActions';
import { useAppDispatch } from '@app/web/src/hooks/redux';

export default function useSwitchClub() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  return useCallback(
    (slug: string, isJoined: boolean) => {
      // If there is no slug in the URL, no redirection will be performed.
      if (!router.query.clubSlug && !router.query.slug) {
        dispatch(updateViewedSlug(slug));
        return;
      }

      if (!isJoined) {
        router.push(`/club/${slug}`);
      }

      if (router.query.slug)
        router.push({
          pathname: router.pathname,
          query: { ...router.query, slug: slug },
        });
      else if (router.query.clubSlug)
        router.push({
          pathname: router.pathname,
          query: { ...router.query, clubSlug: slug },
        });
      else {
        router.push(`/club/${slug}`);
      }
    },
    [dispatch, router]
  );
}

import { useCallback } from 'react';
import {
  apis,
  QueryThreadViewType,
  ThreadViewType,
  useAuth,
  useThreadViews,
} from '@lib/web/apis';
import { useEmojiSource } from '@lib/web/hooks';
import { generateClubViewConfig } from '@lib/web/thread/utils/viewUtils';
import { callWithToast } from '@lib/web/utils';

const NEW_CLUB_THREAD_VIEW_NAME = 'Untitled';

type DuplicateClubThreadViewReturn = {
  newViewId?: string;
};

type DuplicateClubThreadViewProps = {
  type: ThreadViewType.ClubAllThread | ThreadViewType.Club;
  threadViewId?: string;
  threadViewUserId?: string;
  name: string;
};

type RemoveClubThreadViewProps = {
  threadViewUserId?: string;
};

type CreateClubThreadViewReturn = {
  newViewId?: string;
};

type CreateClubThreadViewProps = {
  customName?: string;
};

export type UseUpdateClubThreadViewReturn = {
  createClubThreadView: (
    params: CreateClubThreadViewProps
  ) => Promise<CreateClubThreadViewReturn>;
  removeClubThreadView: (params: RemoveClubThreadViewProps) => Promise<void>;
  duplicateClubThreadView: (
    params: DuplicateClubThreadViewProps
  ) => Promise<DuplicateClubThreadViewReturn>;
};

export type UseUpdateClubThreadViewProps = {
  clubId?: string;
};

export const useUpdateClubThreadView = ({
  clubId,
}: UseUpdateClubThreadViewProps): UseUpdateClubThreadViewReturn => {
  const { mutate: reloadClubViews } = useThreadViews({
    type: QueryThreadViewType.Club,
  });
  const { member } = useAuth();
  const { getAvailableRandomEmoji } = useEmojiSource();

  const createClubThreadView = useCallback(
    async ({
      customName,
    }: CreateClubThreadViewProps): Promise<CreateClubThreadViewReturn> => {
      const myMemberId = member?.memberId || '';
      const payload = {
        ...generateClubViewConfig({
          type: ThreadViewType.Club,
          clubId: clubId || '',
          selfMemberId: myMemberId,
        }),
        clubId,
        name: customName || NEW_CLUB_THREAD_VIEW_NAME,
        emoji: getAvailableRandomEmoji().code,
      };
      const [res] = await callWithToast(apis.thread.setThreadView(payload), {
        showLoading: false,
      });
      const newViewId = res?.data.threadViewUsers[0].id; // first threadViewUsers id is correct id for Club Thread View

      return { newViewId };
    },
    [clubId, getAvailableRandomEmoji, member?.memberId]
  );

  const removeClubThreadView = useCallback(
    async ({ threadViewUserId }: RemoveClubThreadViewProps): Promise<void> => {
      const existingClubViews = (await reloadClubViews())?.data || [];
      const targetClubView = existingClubViews.find((view) =>
        view.threadViewUsers.find((user) => user.id === threadViewUserId)
      );

      if (targetClubView) {
        await callWithToast(
          apis.thread.deleteThreadView({
            type: ThreadViewType.Club,
            threadViewUserId,
          })
        );
      }
    },
    [reloadClubViews]
  );

  const duplicateClubThreadView = useCallback(
    async ({
      type,
      threadViewId,
      threadViewUserId,
      name,
    }: DuplicateClubThreadViewProps): Promise<DuplicateClubThreadViewReturn> => {
      const payload = {
        type,
        ...(type === ThreadViewType.ClubAllThread && { threadViewId }),
        ...(type === ThreadViewType.Club && { threadViewUserId }),
        name,
        emoji: getAvailableRandomEmoji().code,
      };
      const [res] = await callWithToast(
        apis.thread.duplicateThreadView(payload),
        {
          showLoading: false,
        }
      );
      const newViewId = res?.data.threadViewUsers[0].id; // first threadViewUsers id is correct id for Club Thread View

      return { newViewId };
    },
    [getAvailableRandomEmoji]
  );

  return {
    createClubThreadView,
    removeClubThreadView,
    duplicateClubThreadView,
  };
};

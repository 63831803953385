import { Theme, useMediaQuery } from '@mui/material';
import { useClubSlug } from '@lib/web/hooks';

import useMainLayout from './useMainLayout';

export default function useMenuCurrentClubSlug() {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const clubSlug = useClubSlug();
  const { currentClub } = useMainLayout();
  return mdUp ? clubSlug : currentClub || clubSlug;
}

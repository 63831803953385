import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, useMediaQuery } from '@mui/material';
import AddFriendsLeftPanel from '@app/web/src/widgets/LeftPanels/AddFriendsLeftPanel';
import { OtherAddFriend as OtherAddFriendIcon } from '@front/icon';
import { MenuComps } from '@lib/web/ui';

import useMainLayout from '../../hooks/useMainLayout';

const styles = {
  root: {
    '& .menu-title-bar': {
      px: '12px',
    },
  },
};

export default function AddFriendsMenu() {
  const { t } = useTranslation();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { clearTemporaryMenu } = useMainLayout();
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleCollapse = () => {
    clearTemporaryMenu();
  };

  return (
    <MenuComps
      title={
        <MenuComps.ResponsiveTitle
          title={t('menu.Add Friends')}
          titleIcon={<OtherAddFriendIcon width={16} height={16} />}
          onCollapse={handleCollapse}
          navigation={{
            onBack: clearTemporaryMenu,
          }}
        />
      }
      titleToolTip={false}
      disabledCollapse={!mdUp}
      sx={styles.root}
      scrollProps={{
        scrollableNodeProps: {
          ref: scrollRef,
        },
      }}
    >
      <AddFriendsLeftPanel scrollRef={scrollRef} />
    </MenuComps>
  );
}

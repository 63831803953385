import { usePathname } from 'next/navigation';
import { includesAny } from '@app/web/src/utils/string';

import { ProfileMenuVariant } from '../context';

const getMenuVariant = (pathname: string): ProfileMenuVariant | null => {
  if (includesAny(pathname, '/account')) return 'profile';
  if (includesAny(pathname, '/awards')) return 'awards';
  if (includesAny(pathname, '/my-plan')) return 'subscribe';
  if (includesAny(pathname, '/settings')) return 'settings';
  if (includesAny(pathname, '/wallet')) return 'wallet';
  if (includesAny(pathname, '/discount-code')) return 'discount';
  if (includesAny(pathname, '/marketplace')) return 'avatars';
  if (includesAny(pathname, '/parent-phone-number')) return 'phone';
  if (includesAny(pathname, '/invite-friends')) return 'invite';
  return null;
};

export default function useProfileMenuVariant() {
  const pathname = usePathname();

  if (!pathname) return null;

  const menuVariant = getMenuVariant(pathname);
  return menuVariant;
}

import { Box, Skeleton, Typography } from '@mui/material';
import { MaskIcon } from '@front/ui';

import { styles } from './styles';

export default function LoadingButton() {
  return (
    <Box sx={styles.buttonWrap}>
      <Box sx={styles.button} className="is-disabled">
        <Box sx={styles.buttonAvatarText}>
          <MaskIcon>
            <Skeleton variant="rectangular" width={36} height={36} />
          </MaskIcon>

          <Typography fontWeight="bold">
            <Skeleton width={100} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

import { MouseEvent, useState } from 'react';
import { IaClubCtaAction } from '@app/web/src/ia/club/iaClubCta';
import useIaClubCtaAction from '@app/web/src/ia/club/useIaClubCTAAction';
import { TestAdd as TestAddIcon } from '@front/icon';
import { IconButton } from '@front/ui';

import { styles } from './styles';
export function JoinButton({
  slug,
  name,
  onJoined,
  onAccepted,
}: {
  slug: string;
  name: string;
  onJoined: () => void;
  onAccepted: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const { clubCta, clubCtaAction } = useIaClubCtaAction(slug, {
    onJoined,
    onAccepted,
  });

  const handleJoinClub = async (event: MouseEvent) => {
    event.stopPropagation();
    setLoading(true);
    await clubCtaAction?.action({
      id: slug,
      title: name,
    });
    setLoading(false);
  };
  return (
    <IconButton
      sx={styles.addButton}
      customSize={24}
      disabled={
        !clubCtaAction ||
        (clubCta?.value !== IaClubCtaAction.JoinClub &&
          clubCta?.value !== IaClubCtaAction.AcceptInvite &&
          clubCta?.value !== IaClubCtaAction.RequestToJoinClub)
      }
      onClick={handleJoinClub}
      loading={loading}
    >
      <TestAddIcon />
    </IconButton>
  );
}

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import usePrefetchPages from '@app/web/src/hooks/utils/usePrefetchPages';
import getAnalyticsPageConfig from '@app/web/src/layouts/pageConfig/analytics';
import { Icon } from '@front/ui';
import { SharedUserContext } from '@lib/web/contexts';
import { useIaClubStatus } from '@lib/web/hooks';
import { MenuComps } from '@lib/web/ui';

import useMenuCurrentClubSlug from '../../../../hooks/useMenuCurrentClubSlug';

export default function AnalyticsMenu() {
  const { t } = useTranslation();
  const pageConfig = getAnalyticsPageConfig(t);
  const clubSlug = useMenuCurrentClubSlug();
  const { club, joined } = useIaClubStatus(clubSlug);
  const { sharePath } = useContext(SharedUserContext);

  usePrefetchPages([
    '/club/[slug]/analytics/goals',
    '/club/[slug]/analytics/my-path',
    '/club/[slug]/analytics/weakness',
    '/club/[slug]/analytics/mistakes',
  ]);

  if (!club || joined === undefined)
    return <MenuComps title={<Skeleton width={100} />} />;

  return (
    <MenuComps title={t('analytics.title')}>
      <MenuComps.Section>
        <MenuComps.Button
          icon={<Icon name={pageConfig.comparePath.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/analytics/my-path`}
        >
          {pageConfig.comparePath.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.dailyGoals.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/analytics/goals`}
        >
          {pageConfig.dailyGoals.title}
        </MenuComps.Button>
      </MenuComps.Section>
      <MenuComps.Section>
        <MenuComps.SubTitle title={t('analytics.weakness.title')} />
        <MenuComps.Button
          icon={<Icon name={pageConfig.weaknessMistakes.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/analytics/weakness/total-wrong`}
        >
          {pageConfig.weaknessMistakes.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.weaknessOvertime.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/analytics/weakness/overtime`}
        >
          {pageConfig.weaknessOvertime.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.weaknessNotPracticed.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/analytics/weakness/out-of-practice`}
        >
          {pageConfig.weaknessNotPracticed.title}
        </MenuComps.Button>
      </MenuComps.Section>
      <MenuComps.Section>
        <MenuComps.SubTitle title={t('analytics.overall.title')} />
        <MenuComps.Button
          icon={<Icon name={pageConfig.overallMistakes.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/analytics/mistakes/total-wrong`}
        >
          {pageConfig.overallMistakes.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.overallOvertime.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/analytics/mistakes/overtime`}
        >
          {pageConfig.overallOvertime.title}
        </MenuComps.Button>
      </MenuComps.Section>
    </MenuComps>
  );
}

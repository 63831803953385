import { usePathname } from 'next/navigation';
import { includesAny } from '@app/web/src/utils/string';

import { ClubMenuVariant } from '../context';

const getMenuVariant = (pathname: string): ClubMenuVariant | null => {
  if (!/\/club\/.*\//.test(pathname)) return null;
  if (includesAny(pathname, '/thread')) return 'threads';
  if (includesAny(pathname, '/rankings')) return 'rankings';
  if (includesAny(pathname, '/start')) return 'practice';
  if (includesAny(pathname, '/analytics')) return 'analytics';
  if (includesAny(pathname, '/summary')) return 'summary';
  if (includesAny(pathname, '/agents')) return 'agents';
  if (includesAny(pathname, '/workflow')) return null;
  return 'playlists';
};

export default function useClubMenuVariant() {
  const pathname = usePathname();

  if (!pathname) return null;

  const menuVariant = getMenuVariant(pathname);
  return menuVariant;
}

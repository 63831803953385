import Router from 'next/router';

import useRouterHistory from '../../components/RouteHistory/useRouterHistory';

export default function useBackClick(onBackClick?: (ev?: any) => void) {
  const { prevRoutes, shouldInterrupt, checkRouteChange } = useRouterHistory();

  const handleBackClick = (ev?: any) => {
    if (onBackClick) onBackClick(ev);
    else if (shouldInterrupt) {
      checkRouteChange();
    } else if (prevRoutes.length) {
      Router.back();
    } else {
      Router.push('/');
    }
  };

  return handleBackClick;
}

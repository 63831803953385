import { Trans, useTranslation } from 'react-i18next';
import Link from 'next/link';
import { Box, Typography } from '@mui/material';
import { ChatExplore as ChatExploreIcon } from '@front/icon';
import { TextButton } from '@front/ui';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 1,
    px: '20px',
    mt: 1,
  },
};

export default function NotificationEmptyState() {
  const { t } = useTranslation('notification');

  return (
    <Box sx={styles.root}>
      <Typography variant="body2">
        <Trans
          i18nKey="notification::panel.empty.content"
          default="No notifications. Join a club to receive updates and stay connected."
          components={{
            br: <br />,
          }}
        />
      </Typography>
      <TextButton prefixIcon={<ChatExploreIcon />} component={Link} href="/">
        {t('panel.empty.cta.Discover Clubs')}
      </TextButton>
    </Box>
  );
}

import { MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import {
  ActionClose as ActionCloseIcon,
  ActionMore as ActionMoreIcon,
} from '@front/icon';
import { ResponsiveMenu, TipButton } from '@front/ui';
import { ThreadViewType, useAuth } from '@lib/web/apis';
import { useThreadViewDetails } from '@lib/web/thread/hooks/view/useThreadViewDetails';
import { useUpdateDMThreadView } from '@lib/web/thread/hooks/view/useUpdateDMThreadView';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';

type Options = {
  name: string;
  Icon: (params: { [x: string]: unknown }) => JSX.Element;
  handler: (e: MouseEvent) => void | Promise<void>;
}[];

export type DMMenuThreadViewOptionProps = {
  isHovered: boolean;
  resetHovered: () => void;
  view: GetThreadViewRes;
};

export default function DMMenuThreadViewOption({
  isHovered,
  resetHovered,
  view,
}: DMMenuThreadViewOptionProps): JSX.Element {
  const { t } = useTranslation('thread');
  const router = useRouter();
  const { member } = useAuth();
  const toggleButtonRef = useRef<HTMLButtonElement | null>(null);
  const [openOption, setOpenOption] = useState(false);

  const { reloadViews: reloadDmViews } = useDirectMessageViews();
  const { removeDMThreadView } = useUpdateDMThreadView();
  const { getThreadViewDetail } = useThreadViewDetails();

  const { viewType, viewUserIds } = getThreadViewDetail(view);

  const routerViewId = router.query.viewId;

  const isMeView = useMemo(() => {
    return viewUserIds.length === 1 && viewUserIds[0] === member?.memberId;
  }, [member?.memberId, viewUserIds]);

  const showOptionMenu = useMemo(() => {
    return viewType === ThreadViewType.DmOneOnOne && !isMeView && isHovered;
  }, [isHovered, isMeView, viewType]);

  const options: Options = [
    {
      name: t('dm.menu.remove', 'Remove Conversation'),
      Icon: ActionCloseIcon,
      handler: async (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setOpenOption(false);
        await removeDMThreadView({ viewId: view.id });
        await reloadDmViews();
        if (routerViewId === view.id) {
          void router.push('/direct-messages/view/everyone');
        }
      },
    },
  ];

  const handleCloseOption = useCallback(
    (e?: MouseEvent | object): void => {
      if (e && 'stopPropagation' in e) {
        e.stopPropagation();
        e.preventDefault();
      }
      setOpenOption(false);
      resetHovered();
    },
    [resetHovered]
  );

  const handleOpenOption = useCallback((e: MouseEvent): void => {
    e.stopPropagation();
    e.preventDefault();
    setOpenOption(true);
  }, []);

  return (
    <>
      {showOptionMenu && (
        <TipButton
          ref={toggleButtonRef}
          customSize={24}
          onClick={handleOpenOption}
          title={t('dm.menu.more', 'More')}
        >
          <ActionMoreIcon />
        </TipButton>
      )}
      <ResponsiveMenu
        open={openOption}
        onClose={handleCloseOption}
        menuProps={{
          anchorEl: toggleButtonRef.current,
          anchorOrigin: {
            vertical: 'center',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'center',
            horizontal: 'left',
          },
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.name} onClick={option.handler}>
              <ListItemIcon>
                <option.Icon width={16} height={16} />
              </ListItemIcon>
              <ListItemText>{option.name}</ListItemText>
            </MenuItem>
          );
        })}
      </ResponsiveMenu>
    </>
  );
}

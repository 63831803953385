import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvitationNewUserItem } from '@app/web/src/hooks/utils/useAhaInvitation';
import { SearchState } from '@app/web/src/utils/search';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';

export default function useMembersLayoutConfig({
  listState,
  newUser,
  displayDataset,
  searchLoading,
  totalCount,
}: {
  listState: SearchState | null;
  newUser: InvitationNewUserItem | null;
  displayDataset: IconListLayoutItemObj[];
  searchLoading?: boolean;
  totalCount?: number;
}) {
  const { t } = useTranslation();
  return useMemo<IaLayoutConfig[]>(() => {
    if (searchLoading) return [];
    if (totalCount === 0) return [];
    if (listState !== SearchState.Searching) return [];
    if (!searchLoading && displayDataset.length === 0 && !newUser) {
      return [];
    }

    if (newUser) {
      return [
        {
          layout: 'icon-list-layout',
          items: [
            {
              id: newUser.email,
              title: newUser.email,
              avatarBlackAndWhite: true,
              actionMap: {
                click: {
                  type: 'event',
                  value: 'inviteToAha',
                  text: t(
                    `club::club.RHS.addFriends.invite.aha.label.${newUser.actionText}`
                  ),
                  actionType: 'textButton',
                  disabled:
                    newUser.actionText === 'accepted' ||
                    newUser.actionText === 'pending',
                },
              },
            },
          ],
        },
      ];
    }
    return [
      {
        layout: 'icon-list-layout',
        items: [
          t('LHS.addFriends.results', { count: totalCount ?? 0 }),
          ...displayDataset,
        ],
      },
    ];
  }, [searchLoading, totalCount, listState, t, displayDataset, newUser]);
}

import React from 'react';
import { Badge } from '@mui/material';
import Box from '@mui/material/Box';
import {
  PrivacyPrivate as PrivacyPrivateIcon,
  ProfileUserPlaceholder as ProfileUserPlaceholderIcon,
} from '@front/icon';
import { SquareAvatar } from '@front/ui';
import { useThread } from '@lib/web/thread/hooks/core/useThread';

const styles = {
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      verticalAlign: 'top !important',
    },
  },
  avatarBackground: {
    position: 'absolute',
    top: 0,
    zIndex: -1,
  },
  avatarPrivateBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: 'background.darker',
      px: 0,
      minWidth: 'unset',
      width: 8,
      height: 8,
    },
  },
};

export type ChannelIconProps = {
  memberId?: string;
  avatarBackgroundCount: number;
  avatarBadge?: 'private';
};

const MAX_AVATAR_BACKGROUND_COUNT = 3;

export default function ChannelIcon({
  memberId,
  avatarBackgroundCount,
  avatarBadge,
}: ChannelIconProps) {
  const { getThreadUser } = useThread();
  const threadUser = getThreadUser(memberId);

  return (
    <Box sx={styles.root}>
      {avatarBadge === 'private' ? (
        <Badge
          overlap="circular"
          sx={styles.avatarPrivateBadge}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<PrivacyPrivateIcon width={6} height={6} />}
        >
          <SquareAvatar size={16} src={threadUser?.image}>
            {threadUser?.name}
          </SquareAvatar>
        </Badge>
      ) : (
        <SquareAvatar size={16} src={threadUser?.image}>
          {threadUser?.name}
        </SquareAvatar>
      )}

      {new Array(Math.min(avatarBackgroundCount, MAX_AVATAR_BACKGROUND_COUNT))
        .fill(null)
        .map((_, index) => (
          <Box
            key={index}
            sx={[
              styles.avatarBackground,
              { left: -(index + 1) * 2 }, // each background 2px spacing
            ]}
          >
            <ProfileUserPlaceholderIcon width={16} height={16} />
          </Box>
        ))}
    </Box>
  );
}

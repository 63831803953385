import { useState } from 'react';
import { TestAdd as TestAddIcon } from '@front/icon';
import { IconButton } from '@front/ui';
import { apis } from '@lib/web/apis';
import { call } from '@lib/web/utils';

import { styles } from './styles';

export default function JoinGroupButton({
  id,
  onJoined,
}: {
  id: string;
  onJoined?: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const handleJoinClubGroupClick = async () => {
    setLoading(true);
    await call(apis.clubGroup.joinClubGroup(id));
    onJoined?.();
    setLoading(false);
  };

  return (
    <IconButton
      sx={styles.addButton}
      customSize={24}
      loading={loading}
      onClick={handleJoinClubGroupClick}
    >
      <TestAddIcon />
    </IconButton>
  );
}

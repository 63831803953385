import { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material';

import { ClubMenuVariant, MenuVariant, ProfileMenuVariant } from '../context';

import useClubMenuVariant from './useClubMenuVariant';
import useMainLayout from './useMainLayout';
import useMenuVariant from './useMenuVariant';
import useProfileMenuVariant from './useProfileMenuVariant';

type CurrentMenus = {
  // current selected menu in the outer skinny nav
  currentMenu: MenuVariant | null;
  // current selected menu in the club nav
  currentClubMenu: ClubMenuVariant | null;
  // current selected menu in the profile nav
  currentProfileMenu: ProfileMenuVariant | null;
};

export default function useCurrentMenu() {
  const menuVariant = useMenuVariant();
  const clubMenuVariant = useClubMenuVariant();
  const profileMenuVariant = useProfileMenuVariant();
  const { menuTarget, clubMenuTarget, profileMenuTarget } = useMainLayout();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return useMemo<CurrentMenus>(() => {
    const currentMenu = mdUp ? menuVariant : menuTarget || menuVariant;
    const currentClubMenu = mdUp
      ? clubMenuVariant
      : clubMenuTarget || clubMenuVariant;
    const currentProfileMenu = mdUp
      ? profileMenuVariant
      : profileMenuTarget || profileMenuVariant;
    return {
      currentMenu,
      currentClubMenu,
      currentProfileMenu,
    };
  }, [
    mdUp,
    menuTarget,
    menuVariant,
    clubMenuTarget,
    clubMenuVariant,
    profileMenuTarget,
    profileMenuVariant,
  ]);
}

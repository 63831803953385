import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ClickAwayListener } from '@mui/material';
import { SimilarClubViewSlug, useIaSimilarClubsList } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';
import { MenuComps } from '@lib/web/ui';

import useMainLayout from '../../hooks/useMainLayout';

import ClubButton from './ClubGroupMenu/ClubButton';

export default function ClubSuggestionMenu() {
  const { t } = useTranslation();
  const { clearTemporaryMenu } = useMainLayout();

  const clubSlug = useClubSlug();
  const { dataset, isEmpty, mutate } = useIaSimilarClubsList(
    {
      viewSlug: SimilarClubViewSlug.CardCenterDefault,
      search: `clubSlug:${clubSlug}`,
      searchFields: 'clubSlug:eq',
    },
    { enable: !!clubSlug }
  );

  useEffect(() => {
    if (isEmpty) clearTemporaryMenu();
  }, [clearTemporaryMenu, isEmpty]);

  return (
    <ClickAwayListener onClickAway={clearTemporaryMenu}>
      <Box height="100%">
        <MenuComps
          title={t('clubSuggestion.title', 'Club suggestions')}
          onCollapse={clearTemporaryMenu}
        >
          <MenuComps.Section>
            {dataset.map((club) => (
              <ClubButton
                key={club.clubId}
                club={club}
                onJoined={() => mutate()}
                disableSwitchClubAfterJoined
              />
            ))}
          </MenuComps.Section>
        </MenuComps>
      </Box>
    </ClickAwayListener>
  );
}

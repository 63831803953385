import { isNumberic } from './common';

export function toSnakeCase(inputString: string): string {
  return inputString
    .split('')
    .map((character) => {
      if (character === character.toUpperCase()) {
        return `_${character.toLowerCase()}`;
      }
      return character;
    })
    .join('');
}

export function getFirstLetters(
  str: string,
  {
    maxLength,
  }: {
    maxLength?: number;
  } = {}
) {
  const letters = str
    .split(' ')
    .map((s: string) => s.charAt(0))
    .join('');

  if (maxLength) return letters.slice(0, maxLength);

  return letters;
}

export function getOrdinalNum(d: string | number) {
  if (!isNumberic(d)) {
    return '';
  }
  switch (+d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export function compareString(a: string, b: string) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export function includesAny(val: string, ...args: string[]) {
  return args.some((arg) => val.includes(arg));
}

const stringUtils = {
  toSnakeCase,
  getOrdinalNum,
  getFirstLetters,
  compareString,
  includesAny,
};

export default stringUtils;

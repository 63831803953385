import {
  AlbumLayoutConfig,
  AlbumLayoutGroup,
  AlbumLayoutItem,
} from '@lib/ia/src/layouts/AlbumLayout/types';
import { ChallengeStatus, ExamMode } from '@lib/web/apis';

import { roundUpWithoutDecimal } from '../../utils/number';
import { getOrdinalNum } from '../../utils/string';

type ItemRes =
  | GetIaHistoryQuizAlbumCentreDefaultViewRes
  | GetIaChallengeQuizAlbumCentreDefaultViewRes;

export function getIndicatorIcon(status: ChallengeStatus) {
  switch (status) {
    case ChallengeStatus.Expired:
      return 'ChallengeExpired';
    case ChallengeStatus.Ongoing:
      return 'ChallengeOngoing';
    case ChallengeStatus.Invited:
      return 'ChallengeInvited';
    default:
      return 'ChallengeCompleted';
  }
}

function getChallengeInfo(item: ItemRes) {
  if ('challengeInfo' in item) {
    // GetIaHistoryQuizAlbumCentreDefaultViewRes
    return item.challengeInfo;
  }
  return {
    category: item.category,
    rank: item.rank,
    daysLeft: item.daysLeft,
    challengers: item.challengers,
    endDate: item.endDate,
    attempts: item.attempts,
    status: item.status,
  };
}

const percentOf = (value: number) => {
  return `${roundUpWithoutDecimal(value * 100)}%`;
};

export const layoutConfig = (
  groups: AlbumLayoutGroup[],
  markGroup?: AlbumLayoutGroup,
  initLoading = false,
  emptyText = 'No practiced playlists'
): AlbumLayoutConfig => {
  const loadingGroup = initLoading
    ? [
        {
          items: [
            { id: '1', type: 'loading' as const },
            { id: '2', type: 'loading' as const },
          ],
        },
      ]
    : [];

  const displayMarkGroup = markGroup
    ? groups.filter(Boolean).length > 0
      ? [markGroup]
      : []
    : [];

  return {
    layout: 'album-layout',
    settings: {
      gridTemplateColumns: {
        xs: 'repeat(2, 1fr)',
        sm: 'repeat(3, 1fr)',
        md: 'repeat(4, 159.5px)',
      },
      emptyText: emptyText,
    },
    groups: [...displayMarkGroup, ...loadingGroup, ...groups],
  };
};

export const defaultItemMap = (
  slug: string,
  item: ItemRes
): AlbumLayoutItem => {
  const score = roundUpWithoutDecimal(item.score || 0);
  return {
    id: item.quizId,
    type: 'default',
    icon: item.mode === ExamMode.MockExam ? 'MainExam' : 'MainPractice',
    content: `${score}`,
    title: item.playlistName,
    avatars: [
      {
        name: item.creator.displayName,
        avatarUrl: item.creator.nftAvatar || item.creator.avatar,
      },
    ],
    properties: [
      { icon: 'ChatQuestionList', content: item.questionCount },
      { icon: 'ActionCloseThick', content: percentOf(item.mistakeRate) },
      { icon: 'TestClockSolid', content: percentOf(item.overtimeRate) },
    ],

    action: {
      type: 'link',
      value: `/club/${slug}/playlist/${
        item.quizShortId || item.quizId
      }?variant=default`,
    },
  };
};

export const challengeItemMap = (
  slug: string,
  item: ItemRes
): AlbumLayoutItem => {
  const challengeInfo = getChallengeInfo(item);
  const indicatorIcon = getIndicatorIcon(challengeInfo.status);

  return {
    id: item.quizId,
    type: 'default',
    icon: challengeInfo.rank > 0 ? 'MainRank2' : undefined,
    content: `${challengeInfo.rank > 0 ? challengeInfo.rank : ''}`,
    contentSuffix: getOrdinalNum(challengeInfo.rank),
    title: item.playlistName,
    indicatorIcon,
    avatars: challengeInfo.challengers?.map((userId) => ({ userId })) || [],
    properties: [
      { icon: 'ChatQuestionList', content: item.questionCount },
      { icon: 'ActionCloseThick', content: percentOf(item.mistakeRate) },
      { icon: 'TestClockSolid', content: percentOf(item.overtimeRate) },
    ],
    action: {
      type: 'link',
      value: `/club/${slug}/challenge/${
        item.quizShortId || item.quizId
      }?status=${challengeInfo.status}`,
    },
  };
};

export const itemMap =
  (slug: string) =>
  (item: ItemRes): AlbumLayoutItem => {
    const challengeInfo = getChallengeInfo(item);
    if (challengeInfo) return challengeItemMap(slug, item);
    return defaultItemMap(slug, item);
  };

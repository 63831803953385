// eslint-disable-next-line import/prefer-default-export
export const avatarSettings = {
  plan: {
    name: 'Membership Plan',
    avatarIcon: 'ProfileMyPlan',
    avatarBgcolor:
      'linear-gradient(92.61deg, #00D1FF 0%, #0051F9 48.44%, #7000FF 100%)',
  },
  challenge: {
    name: 'Challenge',
    avatarIcon: 'MainChallenge',
    avatarBgcolor: '#00D1FF',
  },
  nextChallenge: {
    name: 'Next Challenge',
    avatarIcon: 'MainChallenge',
    avatarBgcolor: '#00D1FF',
  },
  point: {
    name: 'Point Reward',
    avatarIcon: 'OtherCoins',
    avatarBgcolor: 'linear-gradient(269.99deg, #00FFFF 0.01%, #00A4FF 99.99%)',
  },
  goalRing: {
    name: 'Daily Goals',
    avatarIcon: 'MainSetGoal',
    avatarBgcolor: '#0072DD',
  },
  goalRingAchieved: {
    name: 'Daily Goal Achieved',
    avatarIcon: 'MainSetGoal',
    avatarBgcolor: '#0072DD',
  },
  goalRingCompleted: {
    name: 'Daily Goals Completed',
    avatarIcon: 'MainSetGoal',
    avatarBgcolor: '#0072DD',
  },
  payment: {
    name: 'Aha Purchase',
    avatarIcon: 'OtherShoppingCart',
    avatarBgcolor: '#078504',
  },
  ahaPoints: {
    name: 'Aha Points',
    avatarIcon: 'OtherAhaPoints',
    avatarBgcolor: 'linear-gradient(270deg, #0FF 0%, #00A4FF 100%)',
  },
  streakBroken: {
    name: 'Streak Broken',
    avatarIcon: 'EmojiFire',
    avatarBgcolor: 'linear-gradient(135deg, #F90000 0%, #FC4 100%)',
  },
  streakReminder: {
    name: 'Streak Reminder',
    avatarIcon: 'EmojiFire',
    avatarBgcolor: 'linear-gradient(135deg, #F90000 0%, #FC4 100%)',
  },
  streakAchieved: {
    name: 'Streak Achieved',
    avatarIcon: 'EmojiFire',
    avatarBgcolor: 'linear-gradient(135deg, #F90000 0%, #FC4 100%)',
  },
  email: {
    name: 'Streak Email Verification Reminder',
    avatarIcon: 'SocialMediaEmail',
    avatarBgcolor: '#0072DD',
  },
  verificationEmail: {
    name: '',
    avatarIcon: 'SocialMediaEmail',
    avatarBgcolor: '#0072DD',
    showAvatarOnly: true,
  },
};

import { useMemo } from 'react';
import Router from 'next/router';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import { IaHoverEvent } from '@lib/ia/src/core/types';

import useOpenGlobalProfilePanel from '../../CommonPanels/hooks/useOpenGlobalProfilePanel';

export default function useNotificationAvailableActions() {
  const { openProfile } = useOpenGlobalProfilePanel();
  const { showUserIdProfile } = useFloatingProfile();

  return useMemo(
    () => ({
      openProfile: {
        action: ({ userId }: { userId: string }) => {
          openProfile(userId);
        },
      },
      showFloatingProfile: {
        action: (event: IaHoverEvent<{ userId: string }>) => {
          showUserIdProfile({
            userId: event.target.userId,
            anchorEl: event.anchorEl,
          });
        },
      },
      goToPlaylistDetails: {
        action: ({
          quizId,
          quizShortId,
          clubSlug,
        }: {
          quizId: string;
          quizShortId: string;
          clubSlug: string;
        }) => {
          Router.push(`/club/${clubSlug}/challenge/${quizShortId || quizId}`);
        },
      },
      goToDiscoverClubs: {
        action: () => {
          Router.push('/');
        },
      },
    }),
    [openProfile, showUserIdProfile]
  );
}

import React, {
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { usePathname } from 'next/navigation';
import Router, { useRouter } from 'next/router';
import { Box } from '@mui/material';
import useIaFilterActions from '@app/web/src/hooks/utils/useIaFilterActions';
import { useIaSearchActions } from '@app/web/src/hooks/utils/useIaSearchActions';
import { useLatestValueRef } from '@front/helper';
import { MainBrowse as MainBrowseIcon } from '@front/icon';
import { PropertyType, SearchBar, SquareAvatar, TextButton } from '@front/ui';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import {
  ClubGroupJoinedStatus,
  ClubGroupViewSlug,
  ClubJoinedStatus,
  ClubViewSlug,
  useIaClubGroups,
  useIaClubsList,
} from '@lib/web/apis';
import { useSearchStatus } from '@lib/web/hooks';
import { MenuComps } from '@lib/web/ui';
import { getClubGroupIconPath, getClubIconPath } from '@lib/web/utils';
import { debounce } from 'lodash';

import useMainLayout from '../../../hooks/useMainLayout';

import ClubButton from './ClubButton';
import GroupButton from './ClubGroupButton';
import LoadingButton from './LoadingButton';
import SeeAllButton from './SeeAllButton';
import SuggestionClubs from './SuggestionClubs';
import useSwitchClub from './useSwitchClub';

type SuggestionData = {
  avatar: string;
  display: string;
  path: string;
  isJoined: boolean;
  slug?: string;
};

function JoinedClubs() {
  const joinedClubs = useIaClubsList({
    viewSlug: ClubViewSlug.CardCenterDefault,
    search: `joinStatus:${ClubJoinedStatus.Joined}`,
    searchFields: 'joinStatus:eq',
    limit: 0,
  });

  const joinedGroup = useIaClubGroups({
    viewSlug: ClubGroupViewSlug.CardCenterClubGroupDefault,
    search: `joinedStatus:${ClubGroupJoinedStatus.Joined};expandClubLimit:100`,
    searchFields: 'joinedStatus:eq',
  });

  const inGroupSlugs = useMemo(() => {
    const slugs: string[] = [];

    joinedGroup.dataset.forEach((group) => {
      group.clubs.forEach((club) => {
        if (!slugs.includes(club.clubSlug)) slugs.push(club.clubSlug);
      });
    });

    return slugs;
  }, [joinedGroup.dataset]);
  const displayClubs = useMemo(() => {
    return joinedClubs.dataset.filter(
      (club) => !inGroupSlugs.includes(club.clubSlug)
    );
  }, [inGroupSlugs, joinedClubs.dataset]);
  const { t } = useTranslation();
  const clubsCount = displayClubs.length + inGroupSlugs.length;

  return (
    <>
      <MenuComps.SubTitle
        title={t('switchClub.count', {
          count: clubsCount,
        })}
      />
      {joinedGroup.dataset.map((group) => (
        <GroupButton key={group.id} group={group} />
      ))}
      {displayClubs.map((club) => (
        <ClubButton key={club.clubId} club={club} />
      ))}
    </>
  );
}

function SeeAllClubsButton({ keyword }: { keyword: string }) {
  const { t } = useTranslation();

  const { setKeyword } = useIaSearchActions();
  const { setConditions } = useIaFilterActions();
  const pathname = usePathname();

  const handleSeeAllClubs = async () => {
    if (pathname !== '/') await Router.push('/?searchFrom=clubMenu');
    setKeyword(keyword);
    setConditions([
      {
        id: 'clubCreator',
        field: {
          label: t('filter.joinedStatus.label', 'Joined Status'),
          name: 'joinStatus',
          type: PropertyType.Status,
          options: [
            {
              label: t('filter.joinedStatus.options.joined', 'Joined'),
              value: ClubJoinedStatus.Joined,
              color: 'success',
            },
            {
              label: t('filter.joinedStatus.options.notJoined', 'Not Joined'),
              value: ClubJoinedStatus.NotJoined,
              color: 'default',
            },
          ],
        },
        operator: 'Is',
        values: [
          {
            label: t('filter.joinedStatus.options.notJoined', 'Not Joined'),
            value: ClubJoinedStatus.NotJoined,
          },
        ],
      },
    ]);
  };
  return <SeeAllButton onClick={handleSeeAllClubs} />;
}
function SeeAllGroupsButton({ keyword }: { keyword: string }) {
  const { t } = useTranslation();

  const { setKeyword } = useIaSearchActions();
  const { setConditions } = useIaFilterActions();
  const pathname = usePathname();

  const handleSeeAllGroups = async () => {
    if (pathname !== '/club-group')
      await Router.push('/club-group?searchFrom=clubMenu');

    setKeyword(keyword);
    setConditions([
      {
        id: 'clubCreator',
        field: {
          label: t('filter.joinedStatus.label', 'Joined Status'),
          name: 'joinedStatus',
          type: PropertyType.Status,
          options: [
            {
              label: t('filter.joinedStatus.options.joined', 'Joined'),
              value: ClubGroupJoinedStatus.Joined,
              color: 'success',
            },
            {
              label: t('filter.joinedStatus.options.notJoined', 'Not Joined'),
              value: ClubGroupJoinedStatus.NotJoined,
              color: 'default',
            },
          ],
        },
        operator: 'Is',
        values: [
          {
            label: t('filter.joinedStatus.options.notJoined', 'Not Joined'),
            value: ClubGroupJoinedStatus.NotJoined,
          },
        ],
      },
    ]);
  };
  return <SeeAllButton onClick={handleSeeAllGroups} />;
}

function SearchClubs({
  keyword,
  onDatasetChange,
  onHover,
}: {
  keyword: string;
  onDatasetChange: (dataset: SuggestionData[]) => void;
  onHover: (item: SuggestionData) => void;
}) {
  const { t } = useTranslation();
  const onDatasetChangeRef = useLatestValueRef(onDatasetChange);
  const router = useRouter();
  const { clearTemporaryMenu, triggerTemporaryMenuType } = useMainLayout();
  const handleCollapse = () => {
    clearTemporaryMenu();
  };
  const joinedClubs = useIaClubsList({
    viewSlug: ClubViewSlug.CardCenterDefault,
    search: `joinStatus:${ClubJoinedStatus.Joined}`,
    searchFields: 'joinStatus:eq',
    keywordFuzzy: keyword,
    limit: 0,
  });
  const notJoinedClubs = useIaClubsList({
    viewSlug: ClubViewSlug.CardCenterDefault,
    search: `joinStatus:${ClubJoinedStatus.NotJoined}`,
    searchFields: 'joinStatus:eq',
    keywordFuzzy: keyword,
    limit: 3,
  });
  const joinedGroup = useIaClubGroups({
    viewSlug: ClubGroupViewSlug.CardCenterClubGroupDefault,
    search: `joinedStatus:${ClubGroupJoinedStatus.Joined};expandClubLimit:100`,
    searchFields: 'joinedStatus:eq',
    keywordFuzzy: keyword,
  });
  const notJoinedGroup = useIaClubGroups({
    viewSlug: ClubGroupViewSlug.CardCenterClubGroupDefault,
    search: `joinedStatus:${ClubGroupJoinedStatus.NotJoined}`,
    searchFields: 'joinedStatus:eq',
    keywordFuzzy: keyword,
    limit: 3,
  });

  const handleJoinedGroup = () => {
    notJoinedGroup.mutate();
    joinedGroup.mutate();
  };
  const handleJoinedClub = () => {
    joinedClubs.mutate();
    notJoinedClubs.mutate();
  };

  useEffect(() => {
    onDatasetChangeRef.current([
      ...joinedGroup.dataset.map((group) => ({
        avatar: group.logo || getClubGroupIconPath(group.name),
        display: group.name,
        path: `/club-group/${group.id}`,
        isJoined: true,
      })),
      ...joinedClubs.dataset.map((club) => ({
        avatar: club.clubLogo || getClubIconPath(club.clubName || ''),
        display: club.clubName,
        path: `/club/${club.clubSlug}`,
        slug: club.clubSlug,
        isJoined: true,
      })),
      ...notJoinedGroup.dataset.map((group) => ({
        avatar: group.logo || getClubGroupIconPath(group.name),
        display: group.name,
        path: `/club-group/${group.id}`,
        isJoined: false,
      })),
      ...notJoinedClubs.dataset.map((club) => ({
        avatar: club.clubLogo || getClubIconPath(club.clubName || ''),
        display: club.clubName,
        path: `/club/${club.clubSlug}`,
        slug: club.clubSlug,
        isJoined: false,
      })),
    ]);
  }, [
    joinedGroup.dataset,
    joinedClubs.dataset,
    notJoinedGroup.dataset,
    notJoinedClubs.dataset,
    onDatasetChangeRef,
  ]);

  const handleClubHover = (club: GetIaClubCardCenterDefaultViewRes) => {
    onHover({
      avatar: club.clubLogo || getClubIconPath(club.clubName || ''),
      display: club.clubName,
      path: `/club/${club.clubSlug}`,
      isJoined: club.joinStatus === ClubJoinedStatus.Joined,
    });
  };
  const handleGroupHover = (
    group: GetIaClubGroupCardCenterClubGroupDefaultRes
  ) => {
    onHover({
      avatar: group.logo || getClubGroupIconPath(group.name),
      display: group.name,
      path: `/club-group/${group.id}`,
      isJoined: group.joinedStatus === ClubGroupJoinedStatus.Joined,
    });
  };

  const handleReloadData = () => {
    joinedClubs.mutate();
    notJoinedClubs.mutate();
    notJoinedGroup.mutate();
    joinedGroup.mutate();
  };

  const loading =
    joinedClubs.isLoading ||
    joinedGroup.isLoading ||
    joinedGroup.isLoading ||
    notJoinedClubs.isLoading;

  const isEmpty =
    joinedClubs.isEmpty &&
    joinedGroup.isEmpty &&
    notJoinedClubs.isEmpty &&
    notJoinedGroup.isEmpty;

  const handleDiscoverAll = () => {
    if (router.pathname === '/') {
      if (triggerTemporaryMenuType === 'hovered') handleCollapse();
    } else {
      router.push('/');
    }
  };
  return (
    <Box display="grid" gap={1}>
      {!!joinedGroup.dataset.length && (
        <Box>
          <MenuComps.SubTitle
            title={t('switchClub.count_joined_group', {
              count: joinedGroup.dataset.length,
            })}
          />
          {joinedGroup.dataset.map((group) => (
            <GroupButton
              key={group.id}
              group={group}
              onHover={() => handleGroupHover(group)}
              onLeavedGroup={handleReloadData}
            />
          ))}
        </Box>
      )}
      {!!joinedClubs.dataset.length && (
        <Box>
          <MenuComps.SubTitle
            title={t('switchClub.count_joined', {
              count: joinedClubs.dataset.length,
            })}
          />
          {joinedClubs.dataset.map((club) => (
            <ClubButton
              key={club.clubId}
              club={club}
              onHover={() => handleClubHover(club)}
            />
          ))}
        </Box>
      )}
      {!!notJoinedGroup.totalCount && (
        <Box>
          <MenuComps.SubTitle
            title={t('switchClub.count_notJoined_group', {
              count: notJoinedGroup.totalCount,
            })}
          />
          {notJoinedGroup.dataset.map((group) => (
            <GroupButton
              key={group.id}
              group={group}
              joinGroupDirectly
              onJoinedGroup={handleJoinedGroup}
              onHover={() => handleGroupHover(group)}
              onLeavedGroup={handleReloadData}
            />
          ))}
          {notJoinedGroup.totalCount > 3 && (
            <SeeAllGroupsButton keyword={keyword} />
          )}
        </Box>
      )}
      {!!notJoinedClubs.totalCount && (
        <Box>
          <MenuComps.SubTitle
            title={t('switchClub.count_notJoined', {
              count: notJoinedClubs.totalCount,
            })}
          />
          {notJoinedClubs.dataset.map((club) => (
            <ClubButton
              key={club.clubId}
              club={club}
              onJoined={handleJoinedClub}
              onHover={() => handleClubHover(club)}
            />
          ))}
          {notJoinedClubs.totalCount > 3 && (
            <SeeAllClubsButton keyword={keyword} />
          )}
        </Box>
      )}
      {loading && (
        <Box>
          <LoadingButton />
        </Box>
      )}
      {isEmpty && (
        <Box display="grid" gap={4}>
          <Box display="grid" gap={1}>
            <Box>
              <MenuComps.SubTitle
                title={t('switchClub.count', {
                  count: 0,
                })}
              />
              <MenuComps.SubTitle title={t('switchClub.count.empty')} />
            </Box>
            <Box px={{ xs: 2.5, md: '12px' }}>
              <TextButton
                prefixIcon={<MainBrowseIcon />}
                onClick={handleDiscoverAll}
              >
                {t('switchClub.suggestion.cta')}
              </TextButton>
            </Box>
          </Box>

          <SuggestionClubs />
        </Box>
      )}
    </Box>
  );
}

const selector =
  '[data-testid="switch-club-menu"], [data-testid="club-button"], [data-testid="group-clubs-menu"]';
function DetectMouseEvent() {
  const { clearTemporaryMenu } = useMainLayout();

  useEffect(() => {
    const handleMouseMove = debounce((event: MouseEvent) => {
      if (event.target instanceof Element && event.target.closest(selector)) {
        return;
      }
      clearTemporaryMenu();
    }, 200);

    document.addEventListener('mousemove', handleMouseMove, true);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove, true);
      handleMouseMove.cancel();
    };
  }, [clearTemporaryMenu]);
  return null;
}
export default function ClubGroupMenu() {
  const { t } = useTranslation();
  const [suggestionData, setSuggestionData] = useState<SuggestionData[]>([]);
  const { clearTemporaryMenu, triggerTemporaryMenuType } = useMainLayout();
  const handleCollapse = () => {
    clearTemporaryMenu();
  };
  const searchInputRef = useRef<HTMLInputElement>();
  const {
    isSearching,
    search,
    debouncedSearch,
    focused,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus();

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && suggestionData
  );

  const switchClub = useSwitchClub();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && suggestItem) {
      if (suggestItem.slug) {
        switchClub(suggestItem.slug, suggestItem.isJoined);
      } else {
        Router.push(suggestItem.path);
      }
    }
  };
  return (
    <>
      {triggerTemporaryMenuType === 'hovered' && <DetectMouseEvent />}
      <MenuComps
        data-testid="switch-club-menu"
        title={t('switchClub.title')}
        onCollapse={handleCollapse}
      >
        <MenuComps.Section>
          <Box px="12px">
            <SearchBar
              inputRef={searchInputRef}
              placeholder={t(
                'search.placeholder_joinedClub',
                'Search joined club...'
              )}
              value={search}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyDown={handleKeyDown}
              suggestText={suggestItem?.display as string}
              prefixIcon={
                suggestItem && (
                  <SquareAvatar src={suggestItem.avatar as string} size={16}>
                    {suggestItem.display}
                  </SquareAvatar>
                )
              }
            />
          </Box>
          {isSearching ? (
            <SearchClubs
              keyword={debouncedSearch}
              onDatasetChange={setSuggestionData}
              onHover={updateSuggestItem}
            />
          ) : (
            <JoinedClubs />
          )}
        </MenuComps.Section>
      </MenuComps>
    </>
  );
}

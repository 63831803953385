import React, { MouseEvent, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Router from 'next/router';
import {
  Box,
  ButtonBase,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popper,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useAllJoinedClubs from '@app/web/src/hooks/utils/useAllJoinedClubs';
import useMenuClub from '@app/web/src/hooks/utils/useMenuClub';
import useReloadBaseData from '@app/web/src/hooks/utils/useReloadBaseData';
import { useClubGroupClubs } from '@app/web/src/ia/clubGroup/useClubGroupClubs';
import {
  ActionChevronRightSmall as ActionChevronRightSmallIcon,
  ActionMore as ActionMoreIcon,
  OtherDelete as OtherDeleteIcon,
  ProfileLogout as ProfileLogoutIcon,
  ProfileSetting as ProfileSettingIcon,
} from '@front/icon';
import {
  BottomSheet,
  IconButton,
  popup,
  ResponsiveMenu,
  SquareAvatarProgress,
} from '@front/ui';
import { apis, ClubJoinedStatus, useAuth } from '@lib/web/apis';
import { SharedUserContext } from '@lib/web/contexts';
import { call, getClubGroupIconPath } from '@lib/web/utils';

import ClubButton from './ClubButton';
import JoinGroupButton from './JoinGroupButton';
import NoClubsButton from './NoClubsButton';
import { styles } from './styles';

export default function GroupButton({
  group,
  joinGroupDirectly = false,
  onHover,
  onJoinedGroup,
  onDeletedGroup,
  onLeavedGroup,
}: {
  group: GetIaClubGroupCardCenterClubGroupDefaultRes;
  joinGroupDirectly?: boolean;
  onHover?: () => void;
  onJoinedGroup?: () => void;
  onDeletedGroup?: () => void;
  onLeavedGroup?: () => void;
}) {
  const groupButtonRef = useRef();
  const menuAnchorEl = useRef<HTMLButtonElement | null>(null);
  const { sharePath } = useContext(SharedUserContext);
  const { t } = useTranslation('club');
  const pathname = usePathname();
  const [hovered, setHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { groups: menuGroups } = useMenuClub();
  const { member } = useAuth();
  const { mutate: clubGroupClubsMutate } = useClubGroupClubs(group.id);
  const { clearSpecificGroup } = useAllJoinedClubs();
  const reloadBaseData = useReloadBaseData();
  const userId = member?.userId;
  const isCreator =
    userId && group.creator ? userId === group.creator.clubCreatorId : false;

  const iconPath = group.logo || getClubGroupIconPath(group.name);

  const handleClickMore = (event: MouseEvent) => {
    event.stopPropagation();
    setMenuOpen(true);
  };

  const showDeleteGroupItem = isCreator;
  const showLeaveGroupItem = !isCreator;
  const showLeaveGroupAndClubsItem = !isCreator;

  const handleDeleteClubGroupClick = async () => {
    setMenuOpen(false);
    const confirmed = await popup.promise({
      content: t(
        'clubGroup.toolbar.confirm.deleteGroup',
        'Deleting this club group will move all clubs out of the club group. Are you sure you want to delete your club group?'
      ),
      anchorEl: menuAnchorEl.current,
      confirmText: 'Delete',
      popupType: 'warning',
    });
    if (!confirmed) return;

    setLoading(true);
    await clearSpecificGroup(
      { id: group.id, isLeaveAllClubs: false },
      call(apis.clubGroup.deleteClubGroup(group.id))
    );
    await reloadBaseData();
    setLoading(false);

    onDeletedGroup?.();

    if (pathname === `/club-group/${group.id}`) {
      void Router.push('/club-group');
    }
  };
  const handleLeaveClubGroupClick = async () => {
    setMenuOpen(false);
    const confirmed = await popup.promise({
      content: t(
        'clubGroup.toolbar.confirm.leaveGroup',
        'You will still be a member of the clubs inside this club group. Are you sure you want to leave the club group?'
      ),
      anchorEl: menuAnchorEl.current,
      confirmText: 'Leave',
      popupType: 'warning',
    });
    if (!confirmed) return;

    setLoading(true);
    await clearSpecificGroup(
      { id: group.id, isLeaveAllClubs: false },
      call(
        apis.clubGroup.leaveClubGroup({
          id: group.id,
          leaveAllClubs: false,
        })
      )
    );
    await reloadBaseData();
    setLoading(false);
    onLeavedGroup?.();
    void clubGroupClubsMutate();
  };

  const handleLeaveClubGroupAndClubsClick = async () => {
    setMenuOpen(false);
    const confirmed = await popup.promise({
      content: t(
        'clubGroup.toolbar.confirm.leaveGroupAndClubs',
        'You will leave the club group and all clubs inside of this group. Are you sure you want to leave?'
      ),
      anchorEl: menuAnchorEl.current,
      confirmText: 'Leave',
      popupType: 'warning',
    });
    if (!confirmed) return;

    setLoading(true);
    await clearSpecificGroup(
      { id: group.id, isLeaveAllClubs: true },
      call(
        apis.clubGroup.leaveClubGroup({
          id: group.id,
          leaveAllClubs: true,
        })
      )
    );
    await reloadBaseData();
    setLoading(false);
    onLeavedGroup?.();
    void clubGroupClubsMutate();
  };

  const disableDesktopHovered = joinGroupDirectly || !mdUp || loading;
  const disableMobileHovered = joinGroupDirectly || mdUp || loading;
  return (
    <>
      <Box
        ref={groupButtonRef}
        sx={styles.buttonWrap}
        onMouseEnter={
          disableDesktopHovered ? undefined : () => setHovered(true)
        }
        onMouseLeave={
          disableDesktopHovered ? undefined : () => setHovered(false)
        }
        onClick={disableMobileHovered ? undefined : () => setHovered(true)}
      >
        <ButtonBase
          sx={[
            styles.button,
            (hovered || menuOpen) && !loading && styles.hoveredButton,
            loading && styles.loadingButton,
          ]}
          onMouseEnter={loading ? undefined : onHover}
          component="div"
          disabled={loading}
        >
          <Box sx={styles.buttonAvatarText}>
            <SquareAvatarProgress
              src={iconPath}
              size={36}
              showStacked
              ratio={
                menuGroups.some((menuGroup) => menuGroup.id === group.id)
                  ? 1
                  : 0
              }
            >
              {group.name}
            </SquareAvatarProgress>

            <Typography fontWeight="bold">{group.name}</Typography>
          </Box>
          <Box sx={styles.buttonActions}>
            {joinGroupDirectly ? (
              <JoinGroupButton id={group.id} onJoined={onJoinedGroup} />
            ) : (
              <>
                <IconButton
                  ref={menuAnchorEl}
                  customSize={24}
                  selected={menuOpen}
                  onClick={handleClickMore}
                  loading={loading}
                >
                  <ActionMoreIcon />
                </IconButton>

                <Box sx={styles.icon}>
                  <ActionChevronRightSmallIcon width={16} height={16} />
                </Box>
              </>
            )}
          </Box>
        </ButtonBase>

        {hovered && !menuOpen && !joinGroupDirectly && mdUp && (
          <Popper
            open
            anchorEl={groupButtonRef.current}
            sx={styles.menu}
            placement="right-start"
          >
            <Box data-testid="group-clubs-menu" sx={styles.menuInner}>
              {group.clubs.length === 0 && <NoClubsButton />}
              {group.clubs.map((club) => (
                <ClubButton
                  key={club.clubId}
                  sx={[
                    club.joinStatus === ClubJoinedStatus.Joined && {
                      gridTemplateColumns: '1fr',
                    },
                  ]}
                  club={club}
                />
              ))}
            </Box>
          </Popper>
        )}
      </Box>

      {!menuOpen && !joinGroupDirectly && !mdUp && (
        <BottomSheet
          open={hovered}
          fixedHeight
          onClose={() => setHovered(false)}
        >
          {group.clubs.length === 0 && <NoClubsButton />}
          {group.clubs.map((club) => (
            <ClubButton
              key={club.clubId}
              sx={[
                club.joinStatus === ClubJoinedStatus.Joined && {
                  gridTemplateColumns: '1fr',
                },
              ]}
              club={club}
            />
          ))}
        </BottomSheet>
      )}
      {!joinGroupDirectly && (
        <ResponsiveMenu
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          menuProps={{
            sx: styles.moreMenu,
            anchorEl: menuAnchorEl.current,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          sheetProps={{
            fixedHeight: true,
          }}
        >
          <MenuItem
            component={Link}
            href={`${sharePath}/club-group/form/${group.id}/settings`}
          >
            <ListItemIcon>
              <ProfileSettingIcon width={16} height={16} />
            </ListItemIcon>

            <ListItemText>
              {t('clubGroup.toolbar.clubGroupSettings')}
            </ListItemText>
          </MenuItem>
          {showDeleteGroupItem && (
            <MenuItem
              onClick={handleDeleteClubGroupClick}
              sx={styles.deleteGroupItem}
            >
              <ListItemIcon>
                <OtherDeleteIcon width={16} height={16} />
              </ListItemIcon>
              <ListItemText>{t('clubGroup.toolbar.deleteGroup')}</ListItemText>
            </MenuItem>
          )}
          {showLeaveGroupItem && (
            <MenuItem onClick={handleLeaveClubGroupClick}>
              <ListItemIcon>
                <ProfileLogoutIcon width={16} height={16} />
              </ListItemIcon>

              <ListItemText>
                {t('clubGroup.toolbar.leaveClubGroup')}
              </ListItemText>
            </MenuItem>
          )}
          {showLeaveGroupAndClubsItem && (
            <MenuItem onClick={handleLeaveClubGroupAndClubsClick}>
              <ListItemIcon>
                <ProfileLogoutIcon width={16} height={16} />
              </ListItemIcon>
              <ListItemText>
                {t('clubGroup.toolbar.leaveClubGroupAndClubs')}
              </ListItemText>
            </MenuItem>
          )}
        </ResponsiveMenu>
      )}
    </>
  );
}

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Box, Typography } from '@mui/material';
import useIaClubCtaAction from '@app/web/src/ia/club/useIaClubCTAAction';
import { TestAdd as TestAddIcon } from '@front/icon';
import { IconButton, SquareAvatar } from '@front/ui';
import { ClubJoinedStatus, ClubViewSlug, useIaClubsList } from '@lib/web/apis';
import { MenuComps } from '@lib/web/ui';
import { getClubIconPath } from '@lib/web/utils';

const styles = {
  button: {
    display: 'grid',
    px: { xs: 2.5, md: '12px' },
    gridTemplateColumns: '16px 1fr 24px',
    alignItems: 'center',
    gap: 1,
    height: 30,
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  addButton: {
    bgcolor: 'text.primary',
    color: 'background.default',
  },
};

function ClubButton({
  club,
  onJoined,
}: {
  club: GetIaClubCardCenterDefaultViewRes;
  onJoined: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const { clubCta, clubCtaAction } = useIaClubCtaAction(club.clubSlug, {
    onJoined,
  });

  const handleJoinClick = async () => {
    setLoading(true);
    if (!clubCta || !club) {
      setLoading(false);
      return;
    }

    if (clubCta.type === 'link' || !clubCtaAction) {
      Router.push(clubCta.value);
    } else {
      await clubCtaAction.action({
        id: club.clubSlug,
        title: club.clubName,
      });
    }
    setLoading(false);
  };

  return (
    <IconButton
      sx={styles.addButton}
      customSize={24}
      disabled={club.joinStatus === ClubJoinedStatus.RequestedPending}
      loading={loading}
      onClick={handleJoinClick}
    >
      <TestAddIcon />
    </IconButton>
  );
}
export default function SuggestionClubs() {
  const { t } = useTranslation();

  const recommendedClubs = useIaClubsList({
    viewSlug: ClubViewSlug.CardCenterDefault,
    search: `isRecommended:true;joinStatus:${ClubJoinedStatus.NotJoined}`,
    searchFields: 'isRecommended:eq;joinStatus:eq',
    limit: 3,
  });

  if (recommendedClubs.isEmpty) return null;

  return (
    <Box display="grid" gap={1}>
      <MenuComps.SubTitle title={t('switchClub.suggestion.title')} />
      <Box>
        {recommendedClubs.dataset.map((club) => (
          <Box key={club.clubId} sx={styles.button}>
            <SquareAvatar
              src={club.clubLogo || getClubIconPath(club.clubName)}
              size={16}
            >
              {club.clubName}
            </SquareAvatar>
            <Typography variant="body2" sx={styles.text}>
              {club.clubName}
            </Typography>
            {club.joinStatus !== ClubJoinedStatus.Joined && (
              <ClubButton
                club={club}
                onJoined={() => recommendedClubs.mutate()}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

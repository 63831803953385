import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import useChallengeCount from '@app/web/src/hooks/utils/useChallengeCount';
import useMemberClubRole from '@app/web/src/hooks/utils/useMemberClubRole';
import usePrefetchPages from '@app/web/src/hooks/utils/usePrefetchPages';
import getPlaylistsPageConfig from '@app/web/src/layouts/pageConfig/playlists';
import { Icon } from '@front/ui';
import { SharedUserContext } from '@lib/web/contexts';
import { useIaClubStatus } from '@lib/web/hooks';
import { MenuComps } from '@lib/web/ui';

import useMenuCurrentClubSlug from '../../../../hooks/useMenuCurrentClubSlug';

function usePrefetchData() {
  const clubSlug = useMenuCurrentClubSlug();

  // prefetch data for the challenges page
  void useChallengeCount(clubSlug);
}

export default function PlaylistsMenu() {
  const { t } = useTranslation();
  const clubSlug = useMenuCurrentClubSlug();
  const { club, joined } = useIaClubStatus(clubSlug);
  const { hasEditAuth } = useMemberClubRole();
  void usePrefetchData();
  const pageConfig = getPlaylistsPageConfig(t);
  const { sharePath } = useContext(SharedUserContext);

  usePrefetchPages([
    '/club/[slug]/playlists/history',
    '/club/[slug]/playlists/saved',
    '/club/[slug]/playlists/emoji',
    '/club/[slug]/playlists/friends-playlists',
    '/club/[slug]/playlists/hashtags',
    '/club/[slug]/playlists/creators',
    '/club/[slug]/challenges',
    '/club/[slug]/create-questions',
    '/club/[slug]/review-questions',
  ]);

  if (!club || joined === undefined)
    return <MenuComps title={<Skeleton width={100} />} />;

  return (
    <MenuComps title={t('playlists.title')}>
      <MenuComps.Section>
        <MenuComps.SubTitle title={t('playlists.title')} />
        <MenuComps.Button
          icon={<Icon name={pageConfig.history.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/playlists/history`}
        >
          {pageConfig.history.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.challenges.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/challenges`}
        >
          {pageConfig.challenges.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.savedSessions.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/playlists/saved`}
        >
          {pageConfig.savedSessions.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.reaction.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/playlists/emoji`}
        >
          {pageConfig.reaction.title}
        </MenuComps.Button>
      </MenuComps.Section>

      <MenuComps.Section>
        <MenuComps.SubTitle title={t('playlists.explore.title')} />
        <MenuComps.Button
          icon={<Icon name={pageConfig.following.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/playlists/friends-playlists`}
        >
          {pageConfig.following.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.topics.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/playlists/hashtags`}
        >
          {pageConfig.topics.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.creators.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/playlists/creators`}
        >
          {pageConfig.creators.title}
        </MenuComps.Button>
      </MenuComps.Section>

      <MenuComps.Section>
        <MenuComps.SubTitle title={t('playlists.create.title')} />
        <MenuComps.Button
          icon={<Icon name={pageConfig.myQuestions.icon} />}
          href={`${sharePath}/club/${club.clubSlug}/create-questions`}
        >
          {pageConfig.myQuestions.title}
        </MenuComps.Button>

        {hasEditAuth && (
          <MenuComps.Button
            icon={<Icon name={pageConfig.reviewQuestions.icon} />}
            href={`${sharePath}/club/${club.clubSlug}/review-questions`}
          >
            {pageConfig.reviewQuestions.title}
          </MenuComps.Button>
        )}
      </MenuComps.Section>
    </MenuComps>
  );
}

import { alpha, Theme } from '@mui/material';

export const styles = {
  buttonWrap: {
    py: '1.5px',
    px: 0.5,
    width: '100%',
  },
  buttonSkeleton: {
    width: '100%',
    height: 50,
    borderRadius: 1,
  },
  button: {
    width: '100%',
    height: 50,
    px: 1,
    textAlign: 'left',
    display: 'grid',
    gridTemplateColumns: '1fr 48px',
    gap: 2,
    borderRadius: 1,
    '&:not(.is-disabled):hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    },
  },
  loadingButton: {
    opacity: 0.64,
  },
  hoveredButton: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
  },
  buttonAvatarText: {
    display: 'grid',
    gridTemplateColumns: '36px 1fr',
    alignItems: 'center',
    gap: 1,
    '& .MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  buttonActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
  },
  menu: {
    zIndex: 'tooltip',
    position: 'relative',
  },
  menuInner: {
    py: '6px',
    borderRadius: 1,
    border: '1px solid',
    borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    width: 240,
    bgcolor: 'grey.900',
  },
  addButton: {
    bgcolor: 'text.primary',
    color: 'background.default',
  },
  deleteGroupItem: {
    color: 'error.dark',
  },
  moreMenu: {
    mx: 0,
    mt: 0.5,
  },
  seeAllIcon: {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  noClubsButton: (theme: Theme) => ({
    color: alpha(theme.palette.text.primary, 0.5),
  }),
};

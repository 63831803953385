import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { ChatNotificationDot as ChatNotificationDotIcon } from '@front/icon';
import { useBaseRightPanel } from '@front/ui';
import { SharedUserContext } from '@lib/web/contexts';
import { useIaClubStatus } from '@lib/web/hooks';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import ThreadViewIcon from '@lib/web/thread/ui/ThreadViewIcon';
import {
  isChannelAncestorInTheSameFilter,
  isChannelInTheSameFilter,
} from '@lib/web/thread/utils/channelUtils';
import { MenuComps } from '@lib/web/ui';

import ClubMenuThreadViewOption from './ClubMenuThreadViewOption';
import MenuThreadChannels from './MenuThreadChannels';

const styles = {
  unreadDot: {
    position: 'absolute',
    top: 0,
    right: '36px',
    '& circle': {
      r: 6, // radius 6 will make it 8px (from design)
    },
    '& svg': {
      circle: {
        fill: (theme: Theme) => theme.palette.error.dark,
      },
    },
  },
};

export type ClubMenuThreadViewProps = {
  clubSlug: string;
  view: GetThreadViewRes;
  scrollRef: RefObject<HTMLDivElement>;
  enableOption?: boolean;
};

export default function ClubMenuThreadView({
  clubSlug,
  view,
  scrollRef,
}: ClubMenuThreadViewProps) {
  const { query } = useRouter();
  const pathname = usePathname();
  const { isTargetPanelOpened } = useBaseRightPanel<GlobalPanelParams>();
  const { sharePath } = useContext(SharedUserContext);

  const { viewName, channelFilters } = useThreadViewDetail(view);
  const { chatClient, unreadChannels } = useThread();
  const { club } = useIaClubStatus(clubSlug);
  const [isHovered, setIsHovered] = useState(false);

  const ref = useRef<{
    open: () => void;
    close: () => void;
  }>(null);

  const viewHasUnreadChannels = useMemo(
    () =>
      unreadChannels?.some(
        (channel) =>
          isChannelInTheSameFilter(channel, channelFilters) ||
          isChannelAncestorInTheSameFilter(chatClient, channel, channelFilters)
      ),
    [unreadChannels, channelFilters, chatClient]
  );

  const handleMouseLeave = useCallback((): void => {
    setIsHovered(false);
  }, []);

  const handleMouseEnter = useCallback((): void => {
    setIsHovered(true);
  }, []);

  useEffect(() => {
    if (query.viewId === view.id) {
      // XXX: use setTimeout to wait for component stable, otherwise the open state will be cleared
      setTimeout(() => {
        ref.current?.open();
      });
    }
  }, [query.viewId, view]);

  useEffect(() => {
    ref.current?.close();
  }, [clubSlug]);

  const viewHref = `${sharePath}/club/${clubSlug}/thread/${view.id}`;

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MenuComps.Group
        active={
          pathname === viewHref &&
          !isTargetPanelOpened(GlobalPanelKeys.GlobalThreadChat)
        }
        title={viewName}
        icon={<ThreadViewIcon view={view} size="sm" />}
        ref={ref}
        href={viewHref}
        extraComponent={
          <>
            <ClubMenuThreadViewOption
              isHovered={isHovered}
              resetHovered={handleMouseLeave}
              clubId={club?.clubId}
              view={view}
            />
            {viewHasUnreadChannels && (
              <Box component="span" sx={styles.unreadDot}>
                <ChatNotificationDotIcon />
              </Box>
            )}
          </>
        }
      >
        <MenuThreadChannels
          view={view}
          channelFilters={channelFilters}
          scrollRef={scrollRef}
          getChannelHref={({ viewId, channelCid }) =>
            `/club/${clubSlug}/thread/${viewId}/${channelCid}`
          }
          getIsActive={({ channelCid }) => channelCid === query.cid}
        />
      </MenuComps.Group>
    </Box>
  );
}

import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { Box, ButtonBase, ButtonBaseProps, Typography } from '@mui/material';
import { MainBrowse as MainBrowseIcon } from '@front/icon';
import { MaskIcon } from '@front/ui';

import { styles } from './styles';

export default function SeeAllButton({
  href,
  ...rest
}: ButtonBaseProps & { href?: string }) {
  const { t } = useTranslation();
  const buttonProps = href ? { component: Link, href: href } : {};
  return (
    <Box sx={styles.buttonWrap}>
      <ButtonBase sx={styles.button} {...buttonProps} {...rest}>
        <Box sx={styles.buttonAvatarText}>
          <MaskIcon>
            <Box sx={styles.seeAllIcon}>
              <MainBrowseIcon />
            </Box>
          </MaskIcon>

          <Typography fontWeight="bold">
            {t('switchClub.search.seeAll', 'See all')}
          </Typography>
        </Box>
      </ButtonBase>
    </Box>
  );
}

import { RefObject, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import useAllJoinedClubs from '@app/web/src/hooks/utils/useAllJoinedClubs';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useInfiniteScroll } from '@lib/web/hooks';

import useNotificationAvailableActions from '../hooks/useNotificationAvailableActions';
import useNotificationCustomizeCtas from '../hooks/useNotificationCustomizeCtas';

import ClubsUnreadDropdown from './components/ClubsUnreadDropdown';
import NotificationEmptyState from './components/NotificationEmptyState';
import useNotificationLayoutConfig from './hooks/useNotificationLayoutConfig';
import useNotificationListActions from './hooks/useNotificationListActions';
import useNotificationListData from './hooks/useNotificationListData';

const SKELETON_ITEM_HEIGHT = 58;

const styles = {
  root: {
    py: 1,
    minHeight: 'calc(100vh - 48px)',
  },
  container: {
    mt: 2,
  },
};

type NotificationPanelProps = {
  scrollRef: RefObject<HTMLDivElement>;
};

export default function NotificationPanel({
  scrollRef: containerScrollRef,
}: NotificationPanelProps) {
  const [selected, setSelected] = useState<GetUnreadNotificationClubRes>();
  const { joinedSlugs: joinedClubSlugs } = useAllJoinedClubs();
  const availableActions = useNotificationAvailableActions();
  const customizeCtas = useNotificationCustomizeCtas();
  const { markAllTopicsAsRead } = useNotificationListActions();

  const { notificationData } = useNotificationListData({
    clubSlug: selected?.clubSlug,
  });

  // need to calculate the number of skeletons to generate skeleton fit with height
  // If no, there is a case when too many skeletons => will make vertical scroll appear => Infinite Scroll not working
  const skeletonCount = Math.floor(
    ((containerScrollRef.current?.clientHeight || 0) - 72) /
      SKELETON_ITEM_HEIGHT
  );

  const config = useNotificationLayoutConfig({
    notificationData,
    joinedClubSlugs,
    skeletonCount: Math.max(skeletonCount, 2),
  });

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: notificationData,
  });

  useEffect(() => {
    scrollRef.current = containerScrollRef?.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      markAllTopicsAsRead();
    };
  }, [markAllTopicsAsRead]);

  return (
    <IaRenderContextProvider value={customizeCtas}>
      <IaActionContextProvider availableActions={availableActions}>
        <Box sx={styles.root}>
          <ClubsUnreadDropdown onChange={setSelected} />
          <Box sx={styles.container}>
            <IaLayouts layouts={config} />
            {notificationData.isEmpty && <NotificationEmptyState />}
          </Box>
        </Box>
      </IaActionContextProvider>
    </IaRenderContextProvider>
  );
}

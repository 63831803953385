import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Theme, useMediaQuery } from '@mui/material';
import NotificationPanel from '@app/web/src/widgets/NotificationPage/NotificationPanel';
import { MainNotificationOn as MainNotificationOnIcon } from '@front/icon';
import { useUnreadNotificationClubs } from '@lib/web/apis';
import { MenuComps } from '@lib/web/ui';

import useMainLayout from '../../hooks/useMainLayout';

const styles = {
  root: {
    '& .menu-title-bar': {
      px: '20px',
    },
  },
  redDot: {
    '& .MuiBadge-badge': {
      minWidth: '6px',
      height: '6px',
    },
  },
};

export default function NotificationMenu() {
  const { t } = useTranslation();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { clearTemporaryMenu } = useMainLayout();
  const scrollRef = useRef<HTMLDivElement>(null);

  const { data } = useUnreadNotificationClubs();
  const hasNewNotification = data?.data.some((club) => club.hasNewNotification);

  const handleCollapse = () => {
    clearTemporaryMenu();
  };

  return (
    <MenuComps
      title={
        <MenuComps.ResponsiveTitle
          title={t('menu.Notifications')}
          titleIcon={
            <Badge
              variant="dot"
              color="error"
              sx={styles.redDot}
              invisible={!hasNewNotification}
            >
              <MainNotificationOnIcon width={16} height={16} />
            </Badge>
          }
          onCollapse={handleCollapse}
        />
      }
      disabledCollapse={!mdUp}
      onCollapse={handleCollapse}
      sx={styles.root}
      scrollProps={{
        scrollableNodeProps: {
          ref: scrollRef,
        },
      }}
      titleToolTip={false}
    >
      <NotificationPanel scrollRef={scrollRef} />
    </MenuComps>
  );
}

import { useCallback, useMemo, useState } from 'react';
import { SxProps } from '@mui/material';
import { useAsyncQueryPool } from '@front/helper';
import { apis } from '@lib/web/apis';
import { call, getIndicators } from '@lib/web/utils';

export type User = {
  id: string;
  name?: string;
  image?: string;
  distinctName?: string;
  memberId?: string;
  userId?: string;
  indicators?: { icon: string; name: string; sx?: SxProps }[];
};
export type UserGetter = (userId?: string) => User | null;
const emptyUser = (id: string): User => ({
  id,
});

const deletedUser = (id: string): User => ({
  id,
  name: 'Deleted user',
});

export const useUsers = () => {
  const [userMap, setUserMap] = useState<Record<string, User>>({});

  const { asyncGet: asyncGetUser } = useAsyncQueryPool({
    poolKey: 'useUsers-user',
    map: userMap,
    setMap: setUserMap,
    queryApi: async (keys) => {
      const [res] = await call(apis.member.getInfos({ userIds: keys }));
      if (!res) return;

      return keys.map((key) => {
        const item = res.data.find((d) => d.userId === key);
        return item
          ? ({
              id: key,
              memberId: item.memberId,
              userId: item.userId,
              name: item.displayName || item.distinctName,
              image: item.nftAvatar || item.avatar,
              distinctName: item.distinctName,
              indicators: getIndicators(item.indicator),
            } as User)
          : deletedUser(key);
      });
    },
  });

  const getUser: UserGetter = useCallback(
    (userId) => {
      if (!userId) return null;

      return asyncGetUser(userId) || emptyUser(userId);
    },
    [asyncGetUser]
  );

  return useMemo(
    () => ({
      getUser,
    }),
    [getUser]
  );
};

import { useIaClubGroups } from '@lib/web/apis';
import { useClubSlug, useIaClub } from '@lib/web/hooks';

import { useAppSelector } from '../redux';

import useAllJoinedClubs from './useAllJoinedClubs';

export default function useMenuClub() {
  const lastViewedSlug = useAppSelector((st) => st.layout.lastViewedSlug);
  const { joinedSlugs } = useAllJoinedClubs();

  const clubSlug =
    useClubSlug() || lastViewedSlug || joinedSlugs[0] || undefined;

  const { dataset: clubGroups } = useIaClubGroups(
    {
      filter: 'id;name;joinedStatus',
      expandSearch: `clubs:clubSlug:${clubSlug};`,
      expandSearchFields: 'clubs:clubSlug:eq;',
    },
    { enable: !!clubSlug }
  );

  const { club } = useIaClub(clubSlug);

  return {
    groups: clubGroups,
    club: club,
    slug: clubSlug,
  };
}

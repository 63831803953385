import { TFunction } from 'next-i18next';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { UserGetter } from '@app/web/src/hooks/utils/useUsers';
import { IaRichText, IaRichTextObj } from '@lib/ia/src/core/types';
import { NotificationCtaConfig } from '@lib/ia/src/layouts/ChannelLayout/types';
import { FeedCtaItemConfig } from '@lib/ia/src/layouts/FeedLayout/cta/types';
import { LeagueZone } from '@lib/web/apis';
import {
  getClubIconPath,
  getIndicators,
  getOrdinalNum,
  getTagIconPath,
} from '@lib/web/utils';
import { compareAsc } from 'date-fns';

import { getIndicatorIcon } from '../../HistoryQuizPage/utils';
import { avatarSettings } from '../settings';

type CtaType = NotificationCtaConfig | FeedCtaItemConfig;

export function getSenderInfo(data: GetNotificationRes) {
  if (data.data.payload?.senderName || data.data.icon === 'user') {
    return {
      type: 'user',
      name: data.data.payload.senderName || 'Aha',
      avatar: data.data.payload.senderAvatar || '',
      userId: data.data.payload.senderUserId || '',
      indicators: getIndicators(data.data.payload.senderIndicator),
    };
  }

  if (data.tag.includes('goal.ring.achieved')) {
    return avatarSettings.goalRingAchieved;
  }

  if (data.tag.includes('goal.ring.completed')) {
    return avatarSettings.goalRingCompleted;
  }

  if (data.tag.includes('goal.ring.')) {
    return avatarSettings.goalRing;
  }

  if (data.data.icon === 'challenge') {
    if (
      data.tag === 'challenge.almost.end.scheduler.challenger.club' ||
      data.tag === 'challenge.acceptance.addressee.addressee.club' ||
      data.tag === 'challenge.ending.scheduler.winner.club' ||
      data.tag === 'challenge.ending.scheduler.challenger.club'
    ) {
      const body = data.data.payload.body;
      if (!('ownerChallengeStatus' in body)) {
        // old version
        return {
          type: 'club',
          name: body.clubName,
          avatar: body.clubIcon || getClubIconPath(body.clubName),
          showAvatarOnly: true,
        };
      }
    }
    return avatarSettings.challenge;
  }

  if (
    data.tag.includes('subscription.') ||
    data.tag.includes('free.trial.') ||
    data.data.icon === 'membership'
  ) {
    return avatarSettings.plan;
  }

  if (data.data.icon === 'payment') {
    return avatarSettings.payment;
  }

  if (data.data.icon === 'email') {
    if (
      data.tag === 'email.verification.reminder.global' ||
      data.tag === 'email.verification.success.global'
    ) {
      return avatarSettings.verificationEmail;
    }
    return avatarSettings.email;
  }

  if (data.data.icon === 'aha.points') {
    return avatarSettings.ahaPoints;
  }

  if (data.data.icon === 'club' && data.data.payload.clubName) {
    return {
      type: 'club',
      name: data.data.payload.clubName,
      avatar:
        data.data.payload.clubIcon ||
        getClubIconPath(data.data.payload.clubName),
      showAvatarOnly: data.tag.startsWith('challenge.'),
    };
  }

  if (
    data.tag === 'league.round.finish.stay.tier.club' ||
    data.tag === 'league.round.finish.highest.rank.max.tier.club' ||
    data.tag === 'league.round.finish.highest.tier.club' ||
    data.tag === 'league.enter.promotion.zone.club' ||
    data.tag === 'league.enter.demotion.zone.club' ||
    data.tag === 'league.round.finish.demotion.tier.club' ||
    data.tag === 'league.round.finish.promotion.tier.club'
  ) {
    return {
      type: 'club',
      name: data.data.payload.body.clubName,
      avatar:
        data.data.payload.clubIcon ||
        getClubIconPath(data.data.payload.body.clubName),
    };
  }

  if (data.tag === 'streak.reminder.broken.member.club') {
    return avatarSettings.streakBroken;
  }

  if (data.tag === 'streak.reminder.daily.member.club') {
    return avatarSettings.streakReminder;
  }

  if (
    data.tag === 'streak.achieved.single.day.member.club' ||
    data.tag === 'streak.achieved.multiple.day.member.club'
  ) {
    return avatarSettings.streakAchieved;
  }

  return { name: 'Aha', avatar: '', type: 'aha' };
}

const HREF_REGEX = /(<a\b[^>]*href=["'][^"']*["'][^>]*>.*?<\/a>)/gm;
const LINK_GROUP_REGEX = /<a\s+(?:[^>]*?\s+)?href=["']([^']+)["']>(.+)<\/a>/;
const PROFILE_REGEX = /^\/profile\/(.*)/;
const CLUB_REGEX = /^\/club\/([a-zA-Z0-9-]{1,})$/;
const CHALLENGE_REGEX =
  /^\/club\/([a-zA-Z0-9-]{1,})\/challenge\/([a-zA-Z0-9-]{1,})/;
const SHARED_PLAYLIST_REGEX =
  /^\/shared\/([a-zA-Z0-9-_]{1,})\/([a-zA-Z0-9-]{1,})\/(playlist|playlists)\/([a-zA-Z0-9-]{1,})/;
const SHARED_CHALLENGE_REGEX =
  /^\/shared\/([a-zA-Z0-9-_]{1,})\/([a-zA-Z0-9-]{1,})\/challenge\/([a-zA-Z0-9-]{1,})/;
const PLAYLIST_REGES =
  /^\/club\/([a-zA-Z0-9-]{1,})\/(playlist)\/([a-zA-Z0-9-]{1,})/;
const MARK_PLAYLIST_REGES =
  /^\/club\/([a-zA-Z0-9-]{1,})\/(playlists)\/(hashtags|overtime|emoji|mistakes)/;
const MARKETPLACE_AVATAR_REGEX =
  /^\/marketplace\/(available|unavailable|coming-soon)\?productId=([a-zA-Z0-9-]{1,})/;
const SHARED_SUMMARY_REGEX =
  /^\/shared\/([a-zA-Z0-9-_]{1,})\/([a-zA-Z0-9-]{1,})\/summary(\/([a-zA-Z0-9-]{1,}))?/;
const OLD_SHARED_REGEX = /^(\/shared\/[^\\/]+)(\/.*)$/;
const getIcon = (className: string, src = '', alt = '') => {
  switch (className) {
    case 'avatar': {
      return src;
    }
    case 'avatar-club': {
      return src || getClubIconPath(alt);
    }
    case 'avatar-challenge': {
      return getTagIconPath('MainChallenge');
    }
    case 'avatar-playlist': {
      return getTagIconPath('ProfilePlaylist');
    }
    default: {
      return '';
    }
  }
};

export type MappingDataMetadata = {
  joinedClubSlugs: string[];
  lastReadTime?: string | undefined;
  itemDateDividerTime?: string;
  previousItemDateDividerTime?: string | undefined;
};

const replaceNewSharePath = (href: string) => {
  const match = href.match(OLD_SHARED_REGEX);
  if (match) {
    return `${match[1]}/club${match[2]}`;
  } else {
    return href;
  }
};
export function transToRichText(
  content: string,
  { joinedClubSlugs }: MappingDataMetadata,
  getUser: UserGetter
): IaRichText {
  const contentArr = content.split(HREF_REGEX);

  if (!contentArr.length) return content;
  const output: IaRichTextObj[] = [];

  contentArr.forEach((text: string) => {
    const match = text.match(LINK_GROUP_REGEX);
    if (!match) {
      output.push({
        type: 'text',
        value: text,
      });
    } else {
      let tagObj: IaRichTextObj = {
        type: 'link',
        text: '',
        src: '',
        alt: '',
        action: undefined,
        hoverAction: undefined,
        className: undefined,
        metadata: undefined,
      };
      const href = match[1];
      let renderText = match[2];
      const imageInfo = renderText.match(
        /<img\s[^>]*?class=\s*['"]([^'"]*?)['"][^>]*?src\s*=\s*['"]([^'"]*?)['"][^>]*?>/
      );
      tagObj.text = renderText;

      if (imageInfo) {
        const [imageString, imageClass, imageSrc] = imageInfo;

        renderText = renderText.replace(imageString, '');
        tagObj.src = getIcon(imageClass, imageSrc, renderText);
        tagObj.alt = renderText.replace('@', '');
        tagObj.text = renderText;
      }
      const profileMatch = href.match(PROFILE_REGEX);
      if (profileMatch) {
        tagObj.type = 'tag';
        const user = getUser(profileMatch[1]);
        tagObj.metadata = {
          userId: profileMatch[1],
        };
        tagObj.action = {
          type: 'event',
          value: 'openProfile',
        };
        tagObj.hoverAction = {
          value: 'showFloatingProfile',
        };
        tagObj.className = 'floating-avatar-anchor';

        if (user && user.name !== 'Deleted user') {
          tagObj = {
            type: 'user',
            id: user.userId || '',
            name: user.distinctName ? `@${user.distinctName}` : '',
            src: user.image || '',
            action: {
              type: 'event' as const,
              value: 'openProfile',
            },
            hoverAction: {
              value: 'showFloatingProfile',
            },
            className: 'floating-avatar-anchor',
            indicators: user.indicators,
          };
        }
      } else if (
        CHALLENGE_REGEX.test(href) ||
        PLAYLIST_REGES.test(href) ||
        MARK_PLAYLIST_REGES.test(href)
      ) {
        tagObj.action = {
          type: 'link',
          value: href,
        };
      } else if (
        SHARED_PLAYLIST_REGEX.test(href) ||
        SHARED_CHALLENGE_REGEX.test(href)
      ) {
        tagObj.action = {
          type: 'link',
          value: replaceNewSharePath(href),
        };
      } else if (MARKETPLACE_AVATAR_REGEX.test(href)) {
        tagObj.src = getTagIconPath('ProfileNFTs');
        tagObj.action = {
          type: 'link',
          value: href,
        };
      } else if (SHARED_SUMMARY_REGEX.test(href)) {
        tagObj.src = getTagIconPath('AnalyticsProgress');
        tagObj.action = {
          type: 'link',
          value: replaceNewSharePath(href),
        };
      } else if (CLUB_REGEX.test(href)) {
        const clubMatch = href.match(CLUB_REGEX);
        const clubSlug = clubMatch ? clubMatch[1] : '';
        tagObj.action = {
          type: 'link',
          value: joinedClubSlugs.includes(clubSlug) ? `${href}/start` : href,
        };
      }
      output.push(tagObj);
    }
  });

  return output;
}

/**
 * XXX: In the new design style, we will handle all the content inside front-end,
 * we'll not reply on back-end to generate the notification content like above function transToRichText
 * so for the newly added notificatoin, we'll use this function instead of transToRichText
 */
export function transNewStyleNotificationToRichText(
  item: GetNotificationRes,
  t: TFunction
): IaRichText | null {
  if (item.tag === 'challenge.good.result.member.club') {
    const body = item.data.payload.body;
    return [
      {
        type: 'text',
        value: t('challenge.good.result.member.club.1', 'Impressive score on'),
      },
      {
        type: 'link',
        text: body.quizName || '',
        src: getTagIconPath('MainChallenge'),
        badgeSrc: body.ownerAvatar,
        action: {
          type: 'link',
          value: `/club/${body.clubSlug}/challenge/${
            body.quizShortId || body.quizId
          }`,
        },
        cornerBadgeIcon: getIndicatorIcon(body.ownerChallengeStatus),
      },
      {
        type: 'text',
        value: t(
          'challenge.good.result.member.club.2',
          'Feeling competitive? Challenge other competitors.'
        ),
      },
    ];
  }
  if (item.tag === 'challenge.ending.scheduler.winner.club') {
    const body = item.data.payload.body;
    let quizName;
    let quizId;
    let badgeSrc;
    let cornerBadgeIcon;
    if (!('ownerChallengeStatus' in body)) {
      // old version
      quizName = body.challengeName;
      quizId = body.quizId;
    } else {
      quizName = body.quizName;
      quizId = body.quizShortId || body.quizId;
      badgeSrc = body.ownerAvatar;
      cornerBadgeIcon = getIndicatorIcon(body.ownerChallengeStatus);
    }
    return [
      {
        type: 'text',
        value: t(
          'challenge.ending.scheduler.winner.club.1',
          'Congratulations! 🏆 A challenge has ended, and you are the champion in'
        ),
      },
      {
        type: 'link',
        text: quizName || '',
        src: getTagIconPath('MainChallenge'),
        badgeSrc,
        action: {
          type: 'link',
          value: `/club/${body.clubSlug}/challenge/${quizId}`,
        },
        cornerBadgeIcon,
      },
    ];
  }
  if (item.tag === 'challenge.acceptance.addressee.addressee.club') {
    const body = item.data.payload.body;
    let quizName;
    let quizId;
    let badgeSrc;
    let cornerBadgeIcon;
    if (!('ownerChallengeStatus' in body)) {
      // old version
      quizName = body.challengeName;
      quizId = body.quizId;
    } else {
      quizName = body.quizName;
      quizId = body.quizShortId || body.quizId;
      badgeSrc = body.ownerAvatar;
      cornerBadgeIcon = getIndicatorIcon(body.ownerChallengeStatus);
    }
    return [
      {
        type: 'text',
        value: t(
          'challenge.acceptance.addressee.addressee.club.1',
          'You have joined'
        ),
      },
      {
        type: 'link',
        text: quizName || '',
        src: getTagIconPath('MainChallenge'),
        badgeSrc,
        action: {
          type: 'link',
          value: `/club/${body.clubSlug}/challenge/${quizId}`,
        },
        cornerBadgeIcon,
      },
    ];
  }
  if (item.tag === 'challenge.almost.end.scheduler.challenger.club') {
    const body = item.data.payload.body;
    let quizName;
    let quizId;
    let badgeSrc;
    let cornerBadgeIcon;
    if (!('ownerChallengeStatus' in body)) {
      // old version
      quizName = body.challengeName;
      quizId = body.quizId;
    } else {
      quizName = body.quizName;
      quizId = body.quizShortId || body.quizId;
      badgeSrc = body.ownerAvatar;
      cornerBadgeIcon = getIndicatorIcon(body.ownerChallengeStatus);
    }
    return [
      {
        type: 'text',
        value: t(
          'challenge.almost.end.scheduler.challenger.club.1',
          'Don’t miss out on the chance to prove your skills and claim your victory! You have 24 hours left to conquer'
        ),
      },
      {
        type: 'link',
        text: quizName || '',
        src: getTagIconPath('MainChallenge'),
        badgeSrc,
        action: {
          type: 'link',
          value: `/club/${body.clubSlug}/challenge/${quizId}`,
        },
        cornerBadgeIcon,
      },
    ];
  }
  if (item.tag === 'challenge.ending.scheduler.challenger.club') {
    const body = item.data.payload.body;
    let quizName;
    let quizId;
    let badgeSrc;
    let cornerBadgeIcon;
    let winnerIndicators;
    let winnerAvatar;
    let winnerName;
    let winnerId;
    if (!('ownerChallengeStatus' in body)) {
      // old version
      quizName = body.challengeName;
      quizId = body.quizId;
      winnerAvatar = body.challengeWinner.avatar;
      winnerName = body.challengeWinner.distinctName;
      winnerId = body.challengeWinner.userId;
    } else {
      quizName = body.quizName;
      quizId = body.quizShortId || body.quizId;
      badgeSrc = body.ownerAvatar;
      cornerBadgeIcon = getIndicatorIcon(body.ownerChallengeStatus);
      winnerIndicators = getIndicators(body.winnerIndicatorDyna);
      winnerAvatar = body.winnerAvatarDyna;
      winnerName = body.winnerNameDyna;
      winnerId = body.winnerId;
    }
    return [
      {
        type: 'text',
        value: t(
          'challenge.ending.scheduler.challenger.club.1',
          'This challenge has ended 🏆 and'
        ),
      },
      {
        type: 'user',
        id: winnerId || '',
        name: winnerName || '',
        src: winnerAvatar || '',
        action: {
          type: 'event' as const,
          value: 'openProfile',
        },
        hoverAction: {
          value: 'showFloatingProfile',
        },
        className: 'floating-avatar-anchor',
        indicators: winnerIndicators,
      },
      {
        type: 'text',
        value: t(
          'challenge.ending.scheduler.challenger.club.2',
          'is the champion in'
        ),
      },
      {
        type: 'link',
        text: quizName || '',
        src: getTagIconPath('MainChallenge'),
        badgeSrc,
        action: {
          type: 'link',
          value: `/club/${body.clubSlug}/challenge/${quizId}`,
        },
        cornerBadgeIcon,
      },
      {
        type: 'text',
        value: t(
          'challenge.ending.scheduler.challenger.club.3',
          'Feeling competitive? Challenge other competitors.'
        ),
      },
    ];
  }
  if (item.tag === 'someone.react.to.my.question.club') {
    const body = item.data.payload.body;
    return [
      {
        type: 'text',
        value: t('someone.react.to.my.question.message', {
          emoji: body.markCode,
        }),
      },
      {
        type: 'link',
        text: `${body.quizName} Q${body.quizQuestionNo}`,
        src: getTagIconPath('ProfilePlaylist'),
        badgeSrc: body.creatorAvatar,
        action: {
          type: 'link',
          value: `/club/${body.clubSlug}/playlist/${body.quizId}`,
        },
        inline: true,
      },
      {
        type: 'link',
        text: body.clubName,
        src: body.clubIcon || getClubIconPath(body.clubName),
        action: {
          type: 'link',
          value: `/club/${body.clubSlug}`,
        },
        inline: true,
      },
    ];
  }

  return null;
}

const getLeagueRankCardBorder = (zone: LeagueZone) => {
  if (zone === LeagueZone.Promotion) return 'success.light';
  if (zone === LeagueZone.Stay) return 'text.primary';
  if (zone === LeagueZone.Demotion) return 'error.dark';
  return undefined;
};

/**
 * topicSlug = [clubSlug]-topic
 */
const getClubSlug = (data: GetNotificationRes) => {
  if (!data.topicSlug) return;
  return data.topicSlug.replace(/-topic$/, '');
};

export const getMessageActions = (
  data: GetNotificationRes,
  t: TFunction
): CtaType[] | undefined => {
  const actions: CtaType[] = [];
  const clubSlug = getClubSlug(data);
  data.data.payload?.cta?.forEach((cta) => {
    switch (cta.type) {
      case 'cta.my.analytic': {
        actions.push({
          type: 'button',
          icon: 'MainAnalytics',
          label: t('button.See My Analytics'),
          action: {
            type: 'link',
            value: cta.analyticLink,
          },
          variant: 'outlined',
        });
        break;
      }
      case 'cta.shared.analytic': {
        actions.push({
          type: 'button',
          icon: 'MainAnalytics',
          label: t('button.See Analytics'),
          action: {
            type: 'link',
            value: cta.analyticLink,
          },
          variant: 'outlined',
        });
        break;
      }
      case 'cta.marketplace.avatar.me': {
        actions.push({
          type: 'button',
          icon: 'ProfileNFTs',
          label: t('button.See Avatar'),
          action: {
            type: 'link',
            value: '/marketplace/my-avatars',
          },
          variant: 'outlined',
        });
        break;
      }
      case 'cta.discount.invitation':
      case 'cta.marketplace.main': {
        actions.push({
          type: 'button',
          icon: 'OtherMarketplace',
          label: t('button.Go To Marketplace'),
          action: {
            type: 'link',
            value: '/marketplace/available',
          },
          variant: 'outlined',
        });
        break;
      }
      case 'cta.membership.plan.me': {
        actions.push({
          type: 'button',
          icon: 'ProfileBilling',
          label: t('button.See My Plan'),
          action: {
            type: 'link',
            value: '/profile/my-plan',
          },
          variant: 'outlined',
        });
        break;
      }
      case 'cta.membership.plan.upgrade': {
        actions.push({
          type: 'button',
          icon: 'ProfileUpgrade',
          label: t('button.Upgrade Plan'),
          action: {
            type: 'link',
            value: '/profile/my-plan',
          },
        });
        break;
      }
      case 'cta.my.result.practice':
      case 'cta.my.result.mock': {
        actions.push({
          type: 'button',
          icon: 'TestScoreTally',
          label: t('button.See My Result'),
          action: {
            type: 'link',
            value: cta.resultLink,
          },
        });
        break;
      }
      case 'cta.shared.result.practice':
      case 'cta.shared.result.mock': {
        actions.push({
          type: 'button',
          icon: 'TestScoreTally',
          label: t('button.See Result'),
          action: {
            type: 'link',
            value: replaceNewSharePath(cta.resultLink),
          },
        });
        break;
      }
      case 'cta.aha.point.earned': {
        actions.push({
          type: 'bigCardNumber',
          title: t('Earned'),
          titleIcon: 'ProfilePiggyBank',
          actionIcon: 'ActionArrowRightUp',
          borderLeft: 'gradient.primaryVertical',
          action: {
            type: 'link',
            value: '/profile/wallet',
          },
          value: {
            content: cta.ahaPointTotalAmount,
            prefix: 'OtherAhaPoints',
            prefixType: 'icon',
            variant: 'currency',
          },
          description: {
            content: 'Aha Points',
          },
        });
        break;
      }
      case 'cta.aha.point.spent': {
        actions.push({
          type: 'bigCardNumber',
          title: t('Spent', 'Spent'),
          titleIcon: 'OtherShopping',
          actionIcon: 'ActionArrowRightUp',
          action: {
            type: 'link',
            value: '/profile/wallet',
          },
          value: {
            content: cta.ahaPointTotalAmount,
            prefix: 'OtherAhaPoints',
            prefixType: 'icon',
            variant: 'currency',
          },
          description: {
            content: 'Aha Points',
          },
        });
        break;
      }
      case 'cta.aha.point.balance': {
        actions.push({
          type: 'bigCardNumber',
          title: t('Balance', 'Balance'),
          titleIcon: 'OtherAhaPoints',
          actionIcon: 'ActionArrowRightUp',
          action: {
            type: 'link',
            value: '/profile/wallet',
          },
          value: {
            content: cta.ahaPointTotalAmount,
            prefix: 'OtherAhaPoints',
            prefixType: 'icon',
            variant: 'currency',
          },
          description: {
            content: 'Aha Points',
          },
        });
        break;
      }
      case 'cta.league.rank': {
        actions.push({
          type: 'bigCardNumber',
          title: t('Current Rank'),
          titleIcon: 'TestTrophy',
          borderLeft: getLeagueRankCardBorder(cta.zone),
          value: {
            content: cta.rank,
            suffix: getOrdinalNum(cta.rank),
            variant: 'currency',
          },
          description: {
            content: t('League Zone', { context: cta.zone }),
          },
        });
        break;
      }
      case 'cta.league.final.rank': {
        actions.push({
          type: 'bigCardNumber',
          title: t('Final Rank'),
          titleIcon: 'TestTrophy',
          borderLeft: getLeagueRankCardBorder(cta.zone),
          value: {
            content: cta.rank,
            suffix: getOrdinalNum(cta.rank),
            variant: 'currency',
          },
          description: {
            content: t('## League', { name: cta.prevLeagueName }),
          },
        });
        break;
      }
      case 'cta.challenge.day.remaining': {
        actions.push({
          type: 'bigCardNumber',
          title: t('Remaining'),
          titleIcon: 'TestClock',
          value: {
            content: cta.challengeDaysLeft,
          },
          description: {
            content: cta.challengeDaysLeft <= 1 ? 'Day' : 'Days',
          },
        });
        break;
      }
      case 'cta.league.current': {
        actions.push({
          type: 'customize',
          value: 'cta.league.current',
          metadata: cta,
          clubSlug: clubSlug,
        });
        break;
      }
      case 'cta.goal.ring.complete.one':
      case 'cta.goal.ring.complete.all':
      case 'cta.goal.ring.progress.question':
      case 'cta.goal.ring.progress.mock':
      case 'cta.goal.ring.progress.time': {
        actions.push({
          type: 'customize',
          value: 'cta.goal.ring.*',
          metadata: cta,
          clubSlug: clubSlug,
        });
        break;
      }
      case 'cta.streak.reminder':
      case 'cta.streak.achieved': {
        actions.push({
          type: 'customize',
          value: 'cta.streak.*',
          metadata: { tag: data.tag, ...cta },
          clubSlug: clubSlug,
        });
        break;
      }
      case 'cta.shared.summary': {
        actions.push({
          type: 'customize',
          value: 'cta.shared.summary',
          metadata: cta,
          clubSlug: clubSlug,
        });
        break;
      }
      case 'cta.shared.challenge': {
        actions.push({
          type: 'customize',
          value: 'cta.shared.challenge',
          metadata: {
            challengers: cta.joinedUsers,
            challengeName: cta.challengeName,
            challengeLink: replaceNewSharePath(cta.challengeLink),
          },
          clubSlug: clubSlug,
        });
        break;
      }
      case 'cta.email.verification.remind': {
        actions.push({
          type: 'customize',
          value: 'cta.email.verification.remind',
          clubSlug: clubSlug,
        });
        break;
      }
      case 'cta.discover.club': {
        actions.push({
          type: 'customize',
          value: 'cta.discover.club',
          clubSlug: clubSlug,
        });
        break;
      }
      case 'cta.shared.dynamic.playlist':
      case 'cta.shared.playlist': {
        if (cta.playlistName && cta.questionCount)
          actions.push({
            type: 'customize',
            value: 'cta.shared.*.playlist',
            metadata: {
              playlistLink: replaceNewSharePath(cta.playlistLink),
              playlistName: cta.playlistName,
              questionCount: cta.questionCount,
            },
            clubSlug: clubSlug,
          });
        break;
      }
      case 'cta.membership.plan.info.annual':
      case 'cta.membership.plan.info.monthly': {
        if (cta.subscriptionPrice)
          actions.push({
            type: 'customize',
            value: 'cta.membership.plan.info.*',
            metadata: {
              ...cta,
              isAnnual: cta.type === 'cta.membership.plan.info.annual',
            },
            clubSlug: clubSlug,
          });
        break;
      }
      case 'cta.challenge.invitation': {
        actions.push({
          type: 'customize',
          value: 'cta.challenge.invitation',
          metadata: {
            link: cta.joinLink,
            challengers: cta.joinedUsers,
          },
          clubSlug: clubSlug,
        });
        break;
      }
      case 'cta.challenge.recommend.list':
      case 'cta.recommend.challenger.list': {
        actions.push({
          type: 'customize',
          value: 'cta.challenge.recommend.list',
          metadata: {
            body: data.data.payload.body,
            cta,
          },
          clubSlug: clubSlug,
        });
        break;
      }
      case 'cta.club.invitation': {
        actions.push({
          type: 'customize',
          value: 'cta.club.invitation',
          metadata: {
            clubSlug: cta.clubSlug,
            inviterId: cta.inviterUserId,
          },
          clubSlug: clubSlug,
        });
        break;
      }
      case 'cta.follow.followback': {
        if (cta.userId)
          actions.push({
            type: 'customize',
            value: 'cta.follow.followback',
            metadata: cta,
            clubSlug: clubSlug,
          });
        break;
      }
      case 'cta.club.home': {
        actions.push({
          type: 'customize',
          value: 'cta.club.home',
          metadata: {
            cta,
          },
          clubSlug: clubSlug,
        });
        break;
      }
      case 'cta.user.challenge.status': {
        actions.push({
          type: 'customize',
          value: 'cta.user.challenge.status',
          metadata: {
            cta,
          },
          clubSlug: clubSlug,
        });
        break;
      }
    }
  });

  return actions.length ? actions : undefined;
};

/**
 * Custom position of cta items
 * Each cta item in the list will be named gridArea as cta[index + 1] (cta1, cta2, ...)
 * that can used to arrange them
 */
export const getMessageActionAreas = (
  item: GetNotificationRes
): string | undefined => {
  switch (item.tag) {
    case 'league.round.finish.stay.tier.club':
    case 'league.round.finish.highest.rank.max.tier.club':
    case 'league.round.finish.highest.tier.club':
    case 'league.enter.promotion.zone.club':
    case 'league.enter.demotion.zone.club':
    case 'league.round.finish.demotion.tier.club':
    case 'league.round.finish.promotion.tier.club':
    case 'challenge.invitation.requester.addressee.club':
      return 'cta1 cta2';
  }
};

export const getMessageActionSx = (
  item: GetNotificationRes
): SxProps<Theme> | undefined => {
  const cta = item.data.payload.cta;
  if (!cta || cta.length === 0) return;
  const firstCta = cta[0];
  if (firstCta.type === 'cta.club.home') return { mt: '4px' };
  const ctaButtonTypes = [
    'cta.email.verification.remind',
    'cta.discover.club',
    'cta.club.invitation',
    'cta.follow.followback',
    'cta.user.challenge.status',
  ];
  if (ctaButtonTypes.includes(firstCta.type)) return { mt: 2 };
};

export const getReadState = (
  item: GetNotificationRes,
  { lastReadTime }: MappingDataMetadata
) => {
  // never read, or scheduledAt > lastReadTime
  const isUnread =
    !lastReadTime ||
    compareAsc(new Date(item.scheduledAt), new Date(lastReadTime)) === 1;

  return {
    isUnread,

    /**
     * in notification list, there is no thread inside, so we always apply newThread style when isUnread
     */
    isNewThread: isUnread,
  };
};

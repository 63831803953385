import { useCallback } from 'react';
import { apis, ThreadViewType } from '@lib/web/apis';
import { callWithToast } from '@lib/web/utils';

type RemoveDMThreadViewProps = {
  viewId?: string;
};

export type UseUpdateDMThreadViewReturn = {
  removeDMThreadView: (params: RemoveDMThreadViewProps) => Promise<void>;
};

export const useUpdateDMThreadView = (): UseUpdateDMThreadViewReturn => {
  const removeDMThreadView = useCallback(
    async ({ viewId }: RemoveDMThreadViewProps): Promise<void> => {
      if (viewId) {
        await callWithToast(
          apis.thread.deleteThreadView({
            type: ThreadViewType.DmOneOnOne,
            threadViewId: viewId,
          })
        );
      }
    },
    []
  );

  return {
    removeDMThreadView,
  };
};

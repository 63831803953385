import { useCallback } from 'react';
import {
  apis,
  useClubGroupUnreadNotificationCount,
  useUnreadNotificationClubs,
} from '@lib/web/apis';
import { call } from '@lib/web/utils';

export default function useNotificationListActions() {
  const { mutate: notificationCountMutate } =
    useClubGroupUnreadNotificationCount();
  const { mutate: unreadNotificationClubsMutate } =
    useUnreadNotificationClubs();

  const markAllTopicsAsRead = useCallback(async () => {
    await Promise.all([
      call(apis.notification.read()),
      call(apis.notification.readTopic()),
    ]);
    // should refresh Notification count
    unreadNotificationClubsMutate();
    notificationCountMutate();
  }, [unreadNotificationClubsMutate, notificationCountMutate]);

  return {
    markAllTopicsAsRead,
  };
}

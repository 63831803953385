import { useState } from 'react';
import Router from 'next/router';
import { useMockNotificationList, useNotificationList } from '@lib/web/apis';
import { v4 } from 'uuid';

export default function useNotificationListData({
  clubSlug,
}: {
  clubSlug?: string;
}) {
  const mock = Router.query.mock;
  /**
   * User might receive a new notification, so we make a new key when hook mount
   * to prevent outdated data still appear shortly in the screen
   */
  const [key] = useState(v4());

  const realNotificationData = useNotificationList(
    {
      topicSlug: clubSlug,
      isIncludeAllTopics: !clubSlug,
      isGetTopicDetail: true,
      key,
    },
    20,
    { enable: !mock }
  );

  const mockNotificationsData = useMockNotificationList();

  const notificationData = mock ? mockNotificationsData : realNotificationData;

  return {
    notifications: notificationData.dataset,
    notificationData,
  };
}

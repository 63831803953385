import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import usePrefetchPages from '@app/web/src/hooks/utils/usePrefetchPages';
import getClubPageConfig from '@app/web/src/layouts/pageConfig/club';
import { Icon } from '@front/ui';
import { isDev } from '@lib/web/apis';
import { SharedUserContext } from '@lib/web/contexts';
import { useIaClubStatus } from '@lib/web/hooks';
import { MenuComps } from '@lib/web/ui';

import useMenuCurrentClubSlug from '../../../../hooks/useMenuCurrentClubSlug';

export default function AgentsMenu() {
  const { t } = useTranslation();
  const clubSlug = useMenuCurrentClubSlug();
  const { club, joined } = useIaClubStatus(clubSlug);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { sharePath } = useContext(SharedUserContext);
  const pageConfig = getClubPageConfig(t);
  usePrefetchPages(['/club/[slug]/agents', '/club/[slug]/agents/my-agents']);

  if (!club || joined === undefined)
    return <MenuComps title={<Skeleton width={100} />} />;

  return (
    <MenuComps
      title={t('club.AI.title', 'AI')}
      scrollProps={{
        scrollableNodeProps: {
          ref: scrollRef,
        },
      }}
    >
      <MenuComps.Section>
        <MenuComps.Button
          icon={<Icon name={pageConfig.clubAI.icon} />}
          href={`${sharePath}/club/${clubSlug}/agents`}
        >
          {pageConfig.clubAI.title}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<Icon name={pageConfig.myAI.icon} />}
          href={`${sharePath}/club/${clubSlug}/agents/my-agents`}
        >
          {pageConfig.myAI.title}
        </MenuComps.Button>
        {isDev && (
          <MenuComps.Button
            icon={<Icon name={pageConfig.workflow.icon} />}
            href={`${sharePath}/club/${clubSlug}/agents/workflow`}
          >
            {pageConfig.workflow.title}
          </MenuComps.Button>
        )}
      </MenuComps.Section>
    </MenuComps>
  );
}

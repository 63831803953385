import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useLatestValueRef } from '@front/helper';

export default function usePrefetchPages(pages: string[]) {
  const { prefetch } = useRouter();

  const pagesRef = useLatestValueRef(pages);

  useEffect(() => {
    pagesRef.current.forEach((p) => prefetch(p));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefetch]);
}

import { usePathname } from 'next/navigation';
import {
  ProfileManageAIBot as ProfileManageAIBotIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import { MenuComps } from '@lib/web/ui';

export default function InternalMenu() {
  const pathname = usePathname();

  return (
    <MenuComps title="Internal">
      <MenuComps.Section>
        <MenuComps.SubTitle title="Editor" />
        <MenuComps.Button
          icon={<TestAddIcon />}
          href="/internal/editor"
          active={pathname === '/internal/editor'}
        >
          Editor shortcuts
        </MenuComps.Button>
      </MenuComps.Section>
      <MenuComps.Section>
        <MenuComps.SubTitle title="Thread" />
        <MenuComps.Button
          icon={<ProfileManageAIBotIcon />}
          href="/internal/thread/tools"
        >
          Tools
        </MenuComps.Button>
      </MenuComps.Section>
    </MenuComps>
  );
}

import {
  SanityProductType,
  useMyFavoriteProducts,
  useMyOwnProducts,
  useProducts,
} from '@lib/web/apis';

export default function useProductCount() {
  const { data: products } = useProducts();
  const { data: favorites } = useMyFavoriteProducts();
  const { data: myProducts } = useMyOwnProducts();

  const availableFilter = (product: GetSanityProductRes) =>
    product.status === SanityProductType.Available && product.quantityStock > 0;

  const unavailableFilter = (product: GetSanityProductRes) =>
    product.status === SanityProductType.Unavailable ||
    product.quantityStock === 0;

  const comingSoonFilter = (product: GetSanityProductRes) =>
    product.status === SanityProductType.ComingSoon;

  return {
    data: {
      available: products?.data.filter(availableFilter).length,
      unavailable: products?.data.filter(unavailableFilter).length,
      comingSoon: products?.data.filter(comingSoonFilter).length,
      favorite: favorites?.data.totalCount,
      myAvatars: myProducts?.data.totalCount,
    },
  };
}

import { RefObject, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import useAhaInvitation from '@app/web/src/hooks/utils/useAhaInvitation';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { getSearchState } from '@app/web/src/utils/search';
import {
  LightbulbCard,
  SearchBar,
  SquareAvatar,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import {
  IconListLayoutItemHoverEvent,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';
import {
  apis,
  buildInfiniteHookMutate,
  useAuth,
  useSearchMemberList,
} from '@lib/web/apis';
import { useInfiniteScroll, useSearchStatus } from '@lib/web/hooks';
import { useThreadViewDetails } from '@lib/web/thread/hooks/view/useThreadViewDetails';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';
import { call, emailRegex } from '@lib/web/utils';

import useMembersDataset from './hooks/useMembersDataset';
import useMembersLayoutConfig from './hooks/useMembersLayoutConfig';

const styles = {
  root: {
    minHeight: 'calc(100vh - 48px)',
  },
  content: {
    flex: 1,
    position: 'relative',
  },
  searchBar: {
    px: { xs: '16px', md: '12px' },
  },
  emptyTip: {
    px: { xs: '16px', md: '12px' },
  },
  emptyContent: {
    px: { xs: '16px', md: '12px' },
    pt: 1,
  },
};

type AddFriendsLeftPanelProps = {
  scrollRef: RefObject<HTMLDivElement>;
};

export default function AddFriendsLeftPanel({
  scrollRef: containerScrollRef,
}: AddFriendsLeftPanelProps) {
  const { t } = useTranslation();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const searchInputRef = useRef<HTMLInputElement>();
  const { views } = useDirectMessageViews();
  const { getThreadViewDetail } = useThreadViewDetails();
  const { member } = useAuth();
  const userId = member?.userId || '';
  const { pendingEmails, invitedHistory, inviteToAha, newUserFormatter } =
    useAhaInvitation();

  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus();

  const data = useSearchMemberList(debouncedSearch, undefined, true);
  const { dataset, isLoading: searchLoading, totalCount, mutate } = data;

  const displayDataset = useMembersDataset({ dataset });

  const listState = getSearchState({
    isSearching,
    isFocused: focused,
    hasSelected: false,
  });

  const newUser = useMemo(() => {
    const email =
      isSearching &&
      !dataset.find((d) => d.email === debouncedSearch) &&
      !searchLoading &&
      emailRegex.test(debouncedSearch)
        ? debouncedSearch
        : '';
    return newUserFormatter(email);
  }, [debouncedSearch, isSearching, newUserFormatter, dataset, searchLoading]);

  const configs = useMembersLayoutConfig({
    listState,
    newUser,
    displayDataset,
    totalCount,
    searchLoading,
  });
  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && !newUser && displayDataset
  );
  const { showUserProfile } = useFloatingProfile();

  const isDisabled = (id: string) =>
    id === userId ||
    invitedHistory.some(
      (item) => item.receiver.email === id && !item.resendAble
    );

  const getItemStatus = ({ id }: IconListLayoutItemObj) => {
    return {
      focused: mdUp && suggestItem?.id === id,
      disabled: isDisabled(id),
      loading: pendingEmails.includes(id),
    };
  };

  const reloadMembers = useMemo(
    () => buildInfiniteHookMutate(mutate),
    [mutate]
  );

  const availableActions = {
    userHovered: {
      action: updateSuggestItem,
    },
    titleClick: {
      action: ({ id }: { id: string }) => {
        openRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: id,
        });
      },
    },
    follow: {
      action: async ({ id }: { id: string }) => {
        await call(
          reloadMembers(apis.member.followUser(id), {
            optimisticData: dataset.map((d) =>
              d.userId === id ? { ...d, isFollowing: true } : d
            ),
            optimisticPageData: {
              totalCount,
            },
          })
        );
      },
    },
    titleHover: {
      action: (event: IconListLayoutItemHoverEvent<UserProfileObj>) => {
        if (!event.target.metadata) return;
        showUserProfile({
          anchorEl: event.anchorEl,
          userProfile: event.target.metadata,
        });
      },
    },
    avatarHover: {
      action: (event: IconListLayoutItemHoverEvent<UserProfileObj>) => {
        if (!event.target.metadata) return;
        showUserProfile({
          anchorEl: event.anchorEl,
          userProfile: event.target.metadata,
          options: {
            spacing: 8,
          },
        });
      },
    },
    message: {
      action: async ({ metadata }: IconListLayoutItemObj<UserProfileObj>) => {
        if (!metadata) return;
        const memberId = metadata?.memberId;
        const existingView = views.find((view) => {
          const memberIds = getThreadViewDetail(view).viewMemberIds;
          return memberIds.length === 2 && memberIds.includes(memberId);
        });

        if (existingView) {
          void Router.push(`/direct-messages/view/${existingView.id}`);
        } else {
          void Router.push(`/direct-messages/view/${memberId}`);
        }
      },
    },
    inviteToAha: {
      action: async ({ id }: { id: string }) => {
        inviteToAha([id]);
      },
    },
  };

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: data,
  });

  useEffect(() => {
    scrollRef.current = containerScrollRef?.current;
    searchInputRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyTip = (
    <>
      {displayDataset.length === 0 && !isSearching && (
        <Box sx={styles.emptyTip}>
          <LightbulbCard>
            {t(
              'LHS.addFriends.tip',
              'Search for friends by name or username. Invite new friends by email.'
            )}
          </LightbulbCard>
        </Box>
      )}
    </>
  );

  const emptyContent = (
    <>
      {displayDataset.length === 0 && isSearching && !searchLoading && (
        <Box sx={styles.emptyContent}>
          <Typography variant="body2" color="alpha.lightA64">
            {t('LHS.addFriends.No results found')}
          </Typography>
        </Box>
      )}
    </>
  );

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <Box sx={styles.root}>
          <Box sx={styles.searchBar}>
            <SearchBar
              inputRef={searchInputRef}
              placeholder={t(
                'LHS.addFriends.search.placeholder',
                'Search friends...'
              )}
              value={search}
              loading={searchLoading}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              suggestText={suggestItem?.title}
              prefixIcon={
                suggestItem && (
                  <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                    {suggestItem.title}
                  </SquareAvatar>
                )
              }
              clearButtonSize={32}
            />
          </Box>
          {emptyTip}
          {configs && <IaLayouts layouts={configs} />}
          {emptyContent}
        </Box>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}

import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { MaskIcon } from '@front/ui';

import { styles } from './styles';

export default function NoClubsButton() {
  const { t } = useTranslation();
  return (
    <Box sx={styles.buttonWrap}>
      <Box sx={[styles.button, styles.noClubsButton]} className="is-disabled">
        <Box sx={styles.buttonAvatarText}>
          <MaskIcon>
            <Box bgcolor="grey.700" width={36} height={36} />
          </MaskIcon>

          <Typography fontWeight="bold">
            {t('switchClub.clubs.empty', 'No Clubs')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

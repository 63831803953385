import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Box, Chip, Typography } from '@mui/material';
import {
  ActionChevronDown as ActionChevronDownIcon,
  ActionClose as ActionCloseIcon,
  MainApps as MainAppsIcon,
} from '@front/icon';
import { DropdownButton, DropdownButtonOption, SquareAvatar } from '@front/ui';
import { useUnreadNotificationClubs } from '@lib/web/apis';
import { getClubIconPath } from '@lib/web/utils';

const styles = {
  root: {
    display: 'flex',
    gap: 1,
    px: '20px',
  },
  iconChip: {
    '& .MuiChip-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  labelText: {
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dropdown: {
    '& .MuiButtonBase-root': {
      maxWidth: '265px',
    },
  },
  redDot: {
    '& .MuiBadge-badge': {
      minWidth: '6px',
      height: '6px',
    },
  },
};

type ClubsUnreadDropdownProps = {
  onChange?: (club?: GetUnreadNotificationClubRes) => void;
};

export default function ClubsUnreadDropdown({
  onChange,
}: ClubsUnreadDropdownProps) {
  const { t } = useTranslation('notification');
  const [selected, setSelected] = useState<GetUnreadNotificationClubRes>();

  const { data } = useUnreadNotificationClubs();
  const hasNewNotification = data?.data.some((club) => club.hasNewNotification);

  const handleOptionClick = useCallback(
    (club: GetUnreadNotificationClubRes) => {
      onChange?.(club);
      setSelected(club);
    },
    [onChange]
  );

  const handleClear = () => {
    setSelected(undefined);
    onChange?.();
  };

  const dropdownOptions = useMemo(() => {
    const clubs = data?.data || [];
    const options: DropdownButtonOption[] = clubs.map((club) => {
      const validIconPath = club.clubIcon || getClubIconPath(club.clubName);
      return {
        display: club.clubName,
        icon: (
          <Badge
            variant="dot"
            color="error"
            sx={styles.redDot}
            invisible={!club.hasNewNotification}
          >
            <SquareAvatar src={validIconPath} blackAndWhite size={16}>
              {club.clubName}
            </SquareAvatar>
          </Badge>
        ),
        onClick: () => handleOptionClick(club),
      };
    });
    return options;
  }, [data, handleOptionClick]);

  const isEmpty = !data || data.data.length === 0;

  return (
    <Box sx={styles.root}>
      {selected && (
        <Chip
          sx={styles.iconChip}
          variant="outlined"
          label={<ActionCloseIcon width={16} height={16} />}
          clickable
          onClick={handleClear}
        />
      )}
      <DropdownButton
        options={!isEmpty ? dropdownOptions : []}
        tooltip={{
          icon: 'MainApps',
          title: t('panel.clubsDropdown.tooltip.title', 'Club Notifications'),
          content: isEmpty
            ? t(
                'panel.clubsDropdown.tooltip.emptyContent',
                'There are no notifications from clubs at the moment.'
              )
            : t(
                'panel.clubsDropdown.tooltip.content',
                'Click to display notifications from a specific club.'
              ),
        }}
        dropdownProps={{ sx: styles.dropdown }}
        suffixIconDisabled
      >
        <Box>
          <Chip
            variant={selected ? 'filled' : 'outlined'}
            label={
              <Box sx={styles.label}>
                <Typography sx={styles.labelText}>
                  {selected
                    ? selected.clubName
                    : t('panel.clubsDropdown.label', 'Clubs')}
                </Typography>
                <ActionChevronDownIcon width={16} height={16} />
              </Box>
            }
            icon={
              selected ? (
                <Badge
                  variant="dot"
                  color="error"
                  sx={styles.redDot}
                  invisible={!hasNewNotification}
                >
                  <SquareAvatar
                    src={
                      selected.clubIcon || getClubIconPath(selected.clubName)
                    }
                    blackAndWhite
                    size={16}
                  >
                    {selected.clubName}
                  </SquareAvatar>
                </Badge>
              ) : (
                <Badge
                  variant="dot"
                  color="error"
                  sx={styles.redDot}
                  invisible={!hasNewNotification}
                >
                  <MainAppsIcon width={16} height={16} />
                </Badge>
              )
            }
            clickable
            disabled={isEmpty}
          />
        </Box>
      </DropdownButton>
    </Box>
  );
}
